<template>
    <no-data class="no_data" v-if="loading && !playerData.length"/>
    <div v-else class="player-mian">
        <div v-for="(item, index) in playerData" :key="item.id" class="player-item"
             :style="{ backgroundImage: `url(${playerItemBox})` }" @click="details(item)">
            <div class="player-hand">
                <!-- <div class="player-number akrobatRegular">{{ index + 1 }}</div> -->
                <!-- 伤病 -->
                <index-number
                    class="player-number"
                    :spanColor="pageType === 'injury' && item.injury ? '#D84322' : ''"
                    :iColor="pageType === 'injury' && item.injury ? '#FF5630' : ''"
                >
                    <template v-slot:number>{{ index + 1 }}</template>
                </index-number>
                <img class="player-avatar" :src="$utils.defaultAvatar(item.avatar)"/>
            </div>
            <!-- 伤病 -->
            <p
                v-if="pageType === 'injury'"
                class="player-name"
                :style="{ color: item.injury ? 'red' : '' }"
            >
                {{ item.name }}
            </p>
            <!-- 其他 -->
            <p v-else class="player-name">
                {{ item.number ? `${item.number}&nbsp;&nbsp;` : '' }}{{ item.name }}
            </p>
        </div>
    </div>
</template>

<script>
import {defineComponent, getCurrentInstance, watch, reactive, toRefs} from 'vue';

export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        pageType: {
            type: String,
            default: '',
        }
    },
    emits: ['player-click'],
    setup(props, {emit}) {
        const {proxy} = getCurrentInstance();
        const {playerItemBox, color} = proxy.$Config.UI_CONFIG
        const state = reactive({
            playerData: {},
            playerItemBox,
            color,
            loading: false,
        });
        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    state.playerData = newVal;
                    state.loading = true;
                }
            }
        );
        const details = (row) => {
            emit('player-click', row)
        };
        return {
            ...toRefs(state),
            details,
        }
    },
})
</script>

<style scoped lang="scss">
.player-mian {
    display: grid;
    grid-template-columns: repeat(6, 221px);
    grid-template-rows: auto;
    justify-content: space-between;
    margin: 40px 0 74px 67px;
    width: 1380px;
    height: 709px;

    .player-item {
        width: 221px;
        height: 343.5px;
        margin-bottom: 16px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        .player-hand {
            margin: 27.5px 7px 0;
            height: 227px;
            overflow: hidden;
            background-image: url("~@i/common/avatar-bag.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-sizing: border-box;

            .player-number {
                margin-left: 10px;
                margin-top: 20px;
            }

            .player-avatar {
                margin-top: 20px;
                width: 207px;
            }
        }

        .player-name {
            margin-top: 16px;
            font-weight: 500;
            font-size: 28px;
            line-height: 39px;
            text-align: center;
            color: #fff;
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>