/**
 * 北控紫禁勇士静态json文件 语音
 */
export default {
    mainAudio: ["紫禁勇士", "紫禁", "勇士", "紫金", "永世", "永是", "有事"],
    identify: ["好的", "收到", "好的，收到。"],
    close: ["紫禁勇士不打扰您了，有需要再来找紫禁勇士，再见。", "紫禁勇士先退出了，不打扰您了。", "紫禁勇士先退出了，有什么需要您在来喊我。"],
    notIdentify: ["抱歉，您刚才说的我没有听清，能再说一遍吗？", "抱歉，刚才好像有点吵，能再说一遍吗？", "能在说一下吗？我刚才没听清。", "您刚才在跟我说嘛？抱歉能在说一下吗？"],
    loginSelect: ["请您选择需要登录的用户！"],
    loginSuccess: ["正在登录中请稍后！","马上为您登录！"],

    /** 全局指令
     * key
     * audio 关键词
     * router 识别关键词后跳转页面 0: (go -1)
     * enable 开启关键词的页面name，enable层级大于disable，存在enable将不判断disable
     * disable 禁用关键词的页面name
     * playVoice 识别关键词后播放的话术
     * disableASR: 禁用语音识别
     */
    globalRouse: [
        {
            key: 'login',
            audio: ["登录", "登陆", "识别", "东路"],
            router: {
                path: '/login',
                name: 'login',
            },
            enable: ['home'],
            disable: [],
            playVoice: 'identify',
            disableASR: true
        },
        {
            key: 'close',
            audio: ["退出", "关闭", "退下"],
            router: {
                path: '/',
                name: 'home',
            },
            disable: ['home'],
            playVoice: 'close'
        },
        {
            key: 'back',
            audio: ["返回"],
            router: 0,
            disable: ['home'],
            playVoice: 'identify'
        },
        {
            key: 'backTeam',
            audio: ["回到梯队", "回到开始"],
            router: {
                path: '/team/index',
                name: 'team',
            },
            disable: ['home', 'login', 'team'],
            playVoice: 'identify'
        },
        {
            key: 'backMenu',
            audio: ["菜单", "回到开始"],
            router: {
                path: '/menu/index',
                name: 'mainMenu',
            },
            disable: ['home', 'login', 'team', 'mainMenu'],
            playVoice: 'identify'
        },
    ],

    /**
     * 为了播放跳转后后后后的播放对应语音及主菜单显示 -------*********
     * key 必须要和router内的name一直 必须必须必须
     * path 路由路径
     * name 路由名称
     * hidden 是否显示到主菜单内 true：不显示
     * isConcat 是否需要拼接播放的话术 flag: true需要拼接 concatType：拼接的类型是梯队还是球员。目前仅支持team/player
     * playVoice 跳转后话术
     * playVoiceArr 跳转后话术与playVoice互斥
     * animationType ai人物动画类型 Speak/Head
     * children 子级
     */
    routeList: {
        team: {
            text: "梯队页面",
            router: {
                path: '/team/index',
                name: 'team',
            },
            hidden: true,
            isConcat: {
                flag: false,
                concatType: 'team/player'
            },
            playVoice: '',
            playVoiceArr: ["您好，我是人工智能紫禁勇士。","您好，我叫紫禁勇士。"],
            animationType: 'Speak',
            children: []
        },
        mainMenu: {
            text: "菜单页面",
            router: {
                path: '/menu/index',
                name: 'mainMenu',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的主菜单',
            animationType: 'Head',
            children: []
        },
        talent: {
            text: "精英人才库",
            router: {
                path: '/talent/index',
                name: 'talent',
            },
            hidden: true,
            isConcat: {
                flag: false,
                concatType: 'team'
            },
            playVoice: '这是精英人材库',
            animationType: 'Head',
            children: []
        },
        teamSummary: {
            text: "球队总结",
            router: {
                path: '/teamSummary/index',
                name: 'teamSummary',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球队总结，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/teamSummary.svg'),
            children: []
        },
        trainingPlan: {
            text: "训练计划",
            router: {
                path: '/trainingPlan/index',
                name: 'trainingPlan',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的训练计划，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/trainingPlan.svg'),
            children: []
        },
        injuryReport: {
            text: "伤病报告",
            router: {
                path: '/injuryReport/index',
                name: 'injuryReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的伤病报告，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/injuryReport.svg'),
            children: []
        },
        videoList: {
            text: "视频",
            router: {
                path: '/videoList/index',
                name: 'videoList',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的视频，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/videoList.svg'),
            children: []
        },
        dataReport: {
            text: "数据报告",
            router: {
                path: '/dataReport/index',
                name: 'dataReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的数据报告模块，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/dataReport.svg'),
            children: []
        },
        playerEval: {
            text: "球员评估",
            router: {
                path: '/playerList/index',
                name: 'playerList',
                query: {
                    pageType: 'playerEval',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team',
            },
            playVoice: '的球员评估，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerEval.svg'),
            children: []
        },
        staffEval: {
            text: "教练员评估",
            router: {
                path: '/staffList/index',
                name: 'staffList',
                query: {
                    pageType: 'staffEval',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的教练员评估，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/staffEval.svg'),
            children: []
        },
        playerList: {
            text: "球员信息",
            router: {
                path: '/playerList/index',
                name: 'playerList',
                query: {
                    pageType: 'playerList',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球员列表，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
            children: []
        },
        staffList: {
            text: "教练员信息",
            router: {
                path: '/staffList/index',
                name: 'staffList',
                query: {
                    pageType: 'staffList',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的教练员列表，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/staffList.svg'),
            children: []
        },
        exerciseList: {
            text: "训练教案",
            router: {
                path: '/exerciseList/index',
                name: 'exerciseList',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的训练教案，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/exerciseList.svg'),
            children: []
        },
        dreamTeam: {
            text: "最佳阵容",
            router: {
                path: '/dreamTeam/index',
                name: 'dreamTeam',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的最佳阵容，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/dreamTeam.svg'),
            children: []
        },
    },
    // 数据报告
    dataReport: {
        matchDay: {
            text: "比赛日分析",
            router: {
                path: '/dataReport/matchDay/index',
                name: 'matchDay',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的比赛日分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/matchDay.svg'),
            children: []
        },
        playerAnalysis: {
            text: "球员数据分析",
            router: {
                path: '/dataReport/playerAnalysis/details',
                name: 'playerAnalysis',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球员数据分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/playerAnalysis.svg'),
            children: []
        },
        teamAnalysis: {
            text: "梯队数据分析",
            router: {
                path: '/dataReport/teamAnalysis/index',
                name: 'teamAnalysis',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的梯队数据分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/teamAnalysis.svg'),
            children: []
        },
        intensityReport: {
            text: "强度报告",
            router: {
                path: '/dataReport/intensityReport/index',
                name: 'intensityReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的强度报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/intensityReport.svg'),
            children: []
        },
        acReport: {
            text: "AC报告",
            router: {
                path: '/dataReport/acReport/index',
                name: 'acReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的AC报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/acReport.svg'),
            children: []
        },
        aimReport: {
            text: "AC目标",
            router: {
                path: '/dataReport/aimReport/index',
                name: 'aimReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的AC目标报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/aimReport.svg'),
            children: []
        },
    },

    // 精英人才库
    talentReport: {
        talentDetails: {
            text: "精英人才库详情",
            router: {
                path: '/talent/details',
                name: 'talentDetails',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的详情，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
        }, // 精英人才库详情
        playerDetails: {
            text: "基础信息",
            router: {
                path: '/playerList/details',
                name: 'playerDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的基础信息，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
        }, // 球员详情
        injuryReportDetails: {
            text: "伤病医疗",
            router: {
                path: '/injuryReport/details',
                name: 'injuryReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的伤病医疗，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/injuryReport.svg'),
        },
        playerEvalDetail: {
            text: "评估报告",
            router: {
                path: '/playerList/evalDetail',
                name: 'playerEvalDetail',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的评估报告，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerEval.svg'),
        },
        matchReport: {
            text: "比赛报告",
            router: {
                path: '/matchReport/details',
                name: 'matchReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的比赛报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/matchDay.svg'),
        },
        videoClipsList: {
            text: "视频",
            router: {
                path: '/videoList/clipsList',
                name: 'videoClipsList',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的视频，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/videoList.svg'),
        },
        matchDayDetails: {
            text: "比赛日分析",
            router: {
                path: '/dataReport/matchDay/details',
                name: 'matchDayDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的比赛日分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/matchDay.svg'),
        },
        playerAnalysisDetails: {
            text: "球员数据分析",
            router: {
                path: '/dataReport/playerAnalysis/details',
                name: 'playerAnalysisDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的球员数据分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/playerAnalysis.svg'),
        },
        intensityReportDetails: {
            text: "强度报告",
            router: {
                path: '/dataReport/intensityReport/details',
                name: 'intensityReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的强度报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/intensityReport.svg'),
        },
        acReportDetails: {
            text: "AC报告",
            router: {
                path: '/dataReport/acReport/details',
                name: 'acReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的AC报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/acReport.svg'),
        },
        aimReportDetails: {
            text: "AC目标报告",
            router: {
                path: '/dataReport/aimReport/details',
                name: 'aimReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的AC目标报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/aimReport.svg'),
        },
    }
}