/* eslint-disable */
import _ from 'lodash';

const os = require('os');
const isAudioBuffer = require('is-audio-buffer');

/**
 * e. g. Float32, Uint16LE
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DataView
 */
function getDataViewSuffix(format) {
  return (format.float ? 'Float' : (format.signed ? 'Int' : 'Uint')) + format.bitDepth;
}

/**
 * Default pcm format values
 */
export const defaultFormat = {
  signed: true,
  float: false,
  bitDepth: 16,
  byteOrder: os.endianness instanceof Function ? os.endianness() : 'LE',
  channels: 1,
  sampleRate: 44100,
  interleaved: true,
  samplesPerFrame: 1024,
  id: 'S_16_LE_2_44100_I',
  max: 32678,
  min: -32768,
};


/**
 * Just a list of reserved property names of format
 */
const formatProperties = Object.keys(defaultFormat);

/** Correct default format values */
normalize(defaultFormat);

/**
 * Get format info from any object, unnormalized.
 */
export function getFormat(obj) {
  // undefined format - no format-related props, for sure
  if (!obj) return {};

  // if is string - parse format
  if (typeof obj === 'string' || obj.id) {
    return parse(obj.id || obj);
  }

  // if audio buffer - we know it’s format
  if (isAudioBuffer(obj)) {
    const arrayFormat = fromTypedArray(obj.getChannelData(0));
    return {
      sampleRate: obj.sampleRate,
      channels: obj.numberOfChannels,
      samplesPerFrame: obj.length,
      float: true,
      signed: true,
      bitDepth: arrayFormat.bitDepth,
    };
  }

  // if is array - detect format
  if (ArrayBuffer.isView(obj)) {
    return fromTypedArray(obj);
  }

  // FIXME: add AudioNode, stream detection

  // else detect from obhect
  return fromObject(obj);
}


/**
 * Get format id string.
 * Inspired by https://github.com/xdissent/node-alsa/blob/master/src/constants.coffee
 */
function stringify(format) {
  // TODO: extend possible special formats
  const result = [];

  // (S|U)(8|16|24|32)_(LE|BE)?
  result.push(format.float ? 'F' : (format.signed ? 'S' : 'U'));
  result.push(format.bitDepth);
  result.push(format.byteOrder);
  result.push(format.channels);
  result.push(format.sampleRate);
  result.push(format.interleaved ? 'I' : 'N');

  return result.join('_');
}


/**
 * Return format object from the format ID.
 * Returned format is not normalized for performance purposes (~10 times)
 * http://jsperf.com/parse-vs-extend/4
 */
function parse(str) {
  const params = str.split('_');
  return {
    float: params[0] === 'F',
    signed: params[0] === 'S',
    bitDepth: parseInt(params[1]),
    byteOrder: params[2],
    channels: parseInt(params[3]),
    sampleRate: parseInt(params[4]),
    interleaved: params[5] === 'I',
  };
}


/**
 * Whether one format is equal to another
 */
export function equal(a, b) {
  return (a.id || stringify(a)) === (b.id || stringify(b));
}


/**
 * Normalize format, mutable.
 * Precalculate format params: methodSuffix, id, maxInt.
 * Fill absent params.
 */
export function normalize(format) {
  if (!format) format = {};

  // bring default format values, if not present
  formatProperties.forEach((key) => {
    if (format[key] == null) {
      format[key] = defaultFormat[key];
    }
  });

  // ensure float values
  if (format.float) {
    if (format.bitDepth != 64) format.bitDepth = 32;
    format.signed = true;
  }

  // for words byte length does not matter
  else if (format.bitDepth <= 8) format.byteOrder = '';

  // max/min values
  if (format.float) {
    format.min = -1;
    format.max = 1;
  } else {
    format.max = Math.pow(2, format.bitDepth) - 1;
    format.min = 0;
    if (format.signed) {
      format.min -= Math.ceil(format.max * 0.5);
      format.max -= Math.ceil(format.max * 0.5);
    }
  }

  // calc id
  format.id = stringify(format);

  return format;
}


/** Convert AudioBuffer to Buffer with specified format */
export function toArrayBuffer(audioBuffer, format) {
  if (!isNormalized(format)) format = normalize(format);

  let data;

  // convert to arraybuffer
  if (audioBuffer._data) data = audioBuffer._data.buffer;

  else {
    const floatArray = audioBuffer.getChannelData(0).constructor;
    data = new floatArray(audioBuffer.length * audioBuffer.numberOfChannels);

    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      data.set(audioBuffer.getChannelData(channel), channel * audioBuffer.length);
    }
  }

  const arrayFormat = fromTypedArray(audioBuffer.getChannelData(0));

  const buffer = convert(data, {
    float: true,
    channels: audioBuffer.numberOfChannels,
    sampleRate: audioBuffer.sampleRate,
    interleaved: false,
    bitDepth: arrayFormat.bitDepth,
  }, format);

  return buffer;
}


/** Convert Buffer to AudioBuffer with specified format */
export function toAudioBuffer(buffer, format) {
  if (!isNormalized(format)) format = normalize(format);

  buffer = convert(buffer, format, {
    channels: format.channels,
    sampleRate: format.sampleRate,
    interleaved: false,
    float: true,
  });

  const len = Math.floor(buffer.byteLength * 0.25 / format.channels);

  const audioBuffer = new AudioBuffer({
    length: len,
    numberOfChannels: format.channels,
    sampleRate: format.sampleRate,
  });

  const step = len * 4;
  for (let channel = 0; channel < format.channels; channel++) {
    const offset = channel * step;
    const data = new Float32Array(buffer.slice(offset, offset + step));
    audioBuffer.getChannelData(channel).set(data);
  }

  return audioBuffer;
}


/**
 * Convert buffer from format A to format B.
 */
export function convert(buffer, from, to) {
  // ensure formats are full
  if (!isNormalized(from)) from = normalize(from);
  if (!isNormalized(to)) to = normalize(to);

  // convert buffer/alike to arrayBuffer
  let data;
  if (buffer instanceof ArrayBuffer) {
    data = buffer;
  } else if (ArrayBuffer.isView(buffer)) {
    if (buffer.byteOffset != null) data = buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength);
    else data = buffer.buffer;
  } else {
    data = (new Uint8Array(buffer.length != null ? buffer : [buffer])).buffer;
  }

  // ignore needless conversion
  if (equal(from, to)) {
    return data;
  }

  // create containers for conversion
  let fromArray = new (arrayClass(from))(data);

  // Compression;
  if (from.sampleRate > to.sampleRate) {
    const rawDataLength = fromArray.length;
    const rate = Math.floor(from.sampleRate / to.sampleRate + 0.5);
    const compression = Math.max(rate, 1);
    const newDataLength = Math.floor(rawDataLength / rate);
    if (from.channels == 1) {
      fromArray = _.reduce(fromArray, (_tmpResult, value, idx) => {
        if (idx % compression == 0) {
          _tmpResult[idx / compression] = fromArray[idx];
        }
        return _tmpResult;
      }, new (arrayClass(from))(newDataLength));
    } else if (from.channels == 2) {
      fromArray = _.reduce(fromArray, (_tmpResult, value, idx) => {
        if (idx % (compression * 2) == 0) {
          _tmpResult[idx / compression] = fromArray[idx];
          _tmpResult[idx / compression + 1] = fromArray[idx + 1];
        }
        return _tmpResult;
      }, new (arrayClass(from))(newDataLength));
    }

    // console.log(`Resampled data from ${rawDataLength} to ${fromArray.length}/${newDataLength}`);
  }

  // toArray is automatically filled with mapped values
  // but in some cases mapped badly, e. g. float → int(round + rotate)
  let toArray = new (arrayClass(to))(fromArray);


  // if range differ, we should apply more thoughtful mapping
  if (from.max !== to.max) {
    fromArray.forEach((value, idx) => {
      // ignore not changed range
      // bring to 0..1
      const normalValue = (value - from.min) / (from.max - from.min);

      // bring to new format ranges
      value = normalValue * (to.max - to.min) + to.min;

      // clamp (buffers does not like values outside of bounds)
      toArray[idx] = Math.max(to.min, Math.min(to.max, value));
    });
  }

  // reinterleave, if required
  if (from.interleaved != to.interleaved) {
    const channels = from.channels;
    const len = Math.floor(fromArray.length / channels);

    // deinterleave
    if (from.interleaved && !to.interleaved) {
      toArray = toArray.map((value, idx, data) => {
        const targetOffset = idx % len;
        const targetChannel = ~~(idx / len);

        return data[targetOffset * channels + targetChannel];
      });
    }
    // interleave
    else if (!from.interleaved && to.interleaved) {
      toArray = toArray.map((value, idx, data) => {
        const targetOffset = ~~(idx / channels);
        const targetChannel = idx % channels;

        return data[targetChannel * len + targetOffset];
      });
    }
  }

  // ensure endianness
  if (!to.float && from.byteOrder !== to.byteOrder) {
    const le = to.byteOrder === 'LE';
    const view = new DataView(toArray.buffer);
    const step = to.bitDepth / 8;
    const methodName = `set${getDataViewSuffix(to)}`;
    for (let i = 0, l = toArray.length; i < l; i++) {
      view[methodName](i * step, toArray[i], le);
    }
  }

  return toArray.buffer;
}


/**
 * Check whether format is normalized, at least once
 */
function isNormalized(format) {
  return format && format.id;
}


/**
 * Create typed array for the format, filling with the data (ArrayBuffer)
 */
function arrayClass(format) {
  if (!isNormalized(format)) {
    // eslint-disable-next-line
    format = normalize(format);
  }

  if (format.float) {
    if (format.bitDepth > 32) {
      return Float64Array;
    }

    return Float32Array;
  }

  if (format.bitDepth === 32) {
    return format.signed ? Int32Array : Uint32Array;
  }
  if (format.bitDepth === 8) {
    return format.signed ? Int8Array : Uint8Array;
  }
  // default case

  return format.signed ? Int16Array : Uint16Array;
}


/**
 * Get format info from the array type
 */
function fromTypedArray(array) {
  if (array instanceof Int8Array) {
    return {
      float: false,
      signed: true,
      bitDepth: 8,
    };
  }
  if ((array instanceof Uint8Array) || (array instanceof Uint8ClampedArray)) {
    return {
      float: false,
      signed: false,
      bitDepth: 8,
    };
  }
  if (array instanceof Int16Array) {
    return {
      float: false,
      signed: true,
      bitDepth: 16,
    };
  }
  if (array instanceof Uint16Array) {
    return {
      float: false,
      signed: false,
      bitDepth: 16,
    };
  }
  if (array instanceof Int32Array) {
    return {
      float: false,
      signed: true,
      bitDepth: 32,
    };
  }
  if (array instanceof Uint32Array) {
    return {
      float: false,
      signed: false,
      bitDepth: 32,
    };
  }
  if (array instanceof Float32Array) {
    return {
      float: true,
      signed: false,
      bitDepth: 32,
    };
  }
  if (array instanceof Float64Array) {
    return {
      float: true,
      signed: false,
      bitDepth: 64,
    };
  }

  // other dataview types are Uint8Arrays
  return {
    float: false,
    signed: false,
    bitDepth: 8,
  };
}


/**
 * Retrieve format info from object
 */
function fromObject(obj) {
  // else retrieve format properties from object
  const format = Object.assign({}, obj);

  // formatProperties.forEach(function (key) {
  //   if (obj[key] != null) format[key] = obj[key]
  // })

  // some AudioNode/etc-specific options
  if (!format.channels && (obj.channelCount || obj.numberOfChannels)) {
    format.channels = obj.channelCount || obj.numberOfChannels;
  }
  if (!format.sampleRate && obj.rate) {
    format.sampleRate = obj.rate;
  }

  return format;
}
