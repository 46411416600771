<template>
    <div class="echart" ref="echartRef"></div>
</template>
<script>
import * as echarts from 'echarts';
import { defineComponent, ref, nextTick, watch } from 'vue';

export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => []
        },
    },
    setup(props) {
        const echartRef = ref()
        const echartData = ref()
        const initFn = () => {
            let myChart = echarts.init(echartRef.value);

            let indicatorArr = [], seriesVal = [];
            echartData.value.forEach((item) => {
                indicatorArr.push({
                    name: item.name,
                    max: 5,
                })
                seriesVal.push(item.value)
            })
            myChart.resize();

            let option = {
                color: ['#F8FF2B'],
                tooltip: {
                    show: false // 弹层数据去掉
                },
                radar: {
                    center: ['50%', '60%'], // 外圆的位置
                    name: {
                        show: true, // 是否显示工艺等文字
                        formatter: (params) => {
                            let num;
                            let name;
                            for (let i = 0; i < indicatorArr.length; i++) {
                                if (indicatorArr[i].name == params) {
                                    num = seriesVal[i];
                                    name = indicatorArr[i]['name']
                                }
                            }
                            let html = [
                                '{label|' + name + ' ' + '}{num|' + ' ' + num + '}'
                            ]
                            return html;
                        }, // 工艺等文字的显示形式
                        datas: {
                            indicatorArr: JSON.parse(JSON.stringify(indicatorArr)),
                            seriesVal: seriesVal,
                        },
                        rich: {
                            label: {
                                color: 'rgba(255,255,255,0.5)',// 工艺等文字颜色
                                fontSize: 16,
                            },
                            num: {
                                align: 'center',
                                color: '#fff',
                                fontSize: 22,
                                fontFamily: 'akrobatBold',
                            },
                        },
                    },
                    // TODO:
                    indicator: indicatorArr,
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ['rgba(0, 0, 0, 0)'] // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        }
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.2)'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            width: 1,
                            color: 'rgba(255,255,255,0.3)', // 设置网格的颜色
                        },
                    }
                },
                series: [
                    {
                        type: 'radar',
                        symbol: "none", // 去掉图表中各个图区域的边框线拐点
                        data: [
                            {
                                // TODO:
                                value: seriesVal,
                                name: 'a',
                                areaStyle: {
                                    normal: {
                                        color: 'rgba(252, 255, 33, 0.9)'
                                    }
                                }
                            }
                        ]
                    }
                ]
            };
            myChart.setOption(option, true);
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        }
        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    echartData.value = newVal;
                    nextTick(() => {
                        initFn()
                    })
                }
            }
        );
        return {
            echartRef,
        }
    }
})
</script>
<style lang="scss" scoped>
.echart {
    width: 100%;
    height: 100%;
}
</style>