<template>
    <span
        class="tag_item" 
        :title="item.name"
        :style="{
            borderColor: $utils.hexToRgb(item.color, 0.3), 
            background: $utils.hexToRgb(item.color, 0.4),
        }"
    >
        {{item.name}}
    </span>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        item: {
            type: Object,
            default: () => { },
        }
    },
})
</script>
<style lang='scss' scoped>
.tag_item {
    display: inline-block;
    padding: 0 11px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid transparent;
    border-radius: 14px;
    max-width: 100px;
    height: 28px;
    line-height: 24px;
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    text-overflow: ellipsis; /* 弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 1; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
}
</style>