<template>
    <div class="ai" :style="{width:data.dowWidth + 'px', marginLeft: -marginLeft+'%'}">
        <img v-for="item in data.imgArr" :src="item" alt="">
    </div>
</template>

<script>
import {defineComponent, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default defineComponent({
    name: 'App',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        type: {
            type: String,
            default: "Speak"
        },
        marginLeft: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()
        //
        // console.log(props)
        // console.log(props)
        // console.log(props)
        // let props['type'] = ref(null);


        const state = reactive({})

        const testFn = () => {

            const time = 3000 / 91;
            const W = testRef.value.offsetWidth / 91
            let num = 0;
            let T = setInterval(() => {
                if (num === 90) {
                    clearInterval(T)
                } else {
                    state.marginLeft = W * num + W
                }
                console.log(state.marginLeft)
                num++
            }, time)
        }

        return {
            ...toRefs(state),
            ...toRefs(props)
        }
    }
})
</script>

<style scoped lang="scss">
.ai {
    height: 100%;
    display: flex;
    //width: calc(977.5390625px * 91);

    img {
        height: 100%;
    }
}
</style>