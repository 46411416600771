<template>
    <div class="card">
        <index-number class="number" >
            <template v-slot:number>
                {{ Number(index) + 1 }}
            </template>
        </index-number>
        <div class="img_box">
            <img
                :src="item.image || item.drawingThumbnail || require('@i/exercise/formation_bg.svg')"
                alt
            />
        </div>
        <div class="content">
            <div class="name">{{ item.name || item.title }}</div>
            <div class="tags">
                <tags
                    v-for="(item, index) in item.tags"
                    :key="index"
                    :item="item"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance } from 'vue';
export default defineComponent({
    props: {
        item: {
            type: Object,
            default: () => { },
        },
        index: {
            type: Number,
            default: 0,
        },
        /**
         * 卡片类型
         * 阵型：formation
         * 教案：exercise
         */
        // type: {
        //     type: String,
        //     default: 'formation'
        // }
    },
    setup(props) {
        const { proxy } = getCurrentInstance()
        const { color } = proxy.$Config.UI_CONFIG

        const state = reactive({
            color,
        });

        return {
            ...props,
            ...toRefs(state),
        }
    }
})
</script>
<style lang="scss" scoped>
.card {
    position: relative;
    width: 272px;
    height: 358px;
    border-radius: 6px;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.4);
    filter: drop-shadow(0px 16px 16px rgba(129, 129, 138, 0.08));
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 16px;
    .number {
        position: absolute;
        top: 6px;
        left: 6px;
    }
    .img_box {
        width: 100%;
        height: 358px;
        transition: all 0.3s;
        background: v-bind("color");
        img {
            width: 100%;
            height: 358px;
        }
    }

    .content {
        position: absolute;
        bottom: 0;
        padding: 12px;
        width: 100%;
        height: 97px;
        background: linear-gradient(0deg, rgba(14, 14, 14, 0.7) 0%, rgba(14, 14, 14, 0) 100%);
        box-sizing: border-box;

        .name {
            margin-bottom: 10px;
            width: 100%;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 26px;
            line-height: 36px;
            color: rgba(255, 255, 255, 0.8);
            text-overflow: ellipsis; /* 弹性伸缩盒子模型显示 */
            display: -webkit-box; /* 限制在一个块元素显示的文本的行数 */
            -webkit-line-clamp: 1; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .tags {
            height: 68px;
            overflow: hidden;
        }
    }
    &:hover {
        .content {
            transition: all 0.3s;
            height: 150px;
        }
    }
}
</style>