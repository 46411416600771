<template>
    <div class="echart" ref="evalEchartRef"></div>
</template>
<script>
import * as echarts from 'echarts';
import { defineComponent, ref, nextTick, watch } from 'vue';

export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => []
        },
    },
    emits:['changeEval'],
    setup(props,ctx) {
        const evalEchartRef = ref();
        const initFn = () => {
            let barSeriesData = props.list;
            let thisBarDataX = [];//柱状图个数
            let thisBarData = [];//柱状图数据

            if(barSeriesData.length > 0) {
                barSeriesData.forEach((item)=>{
                    thisBarDataX.push(item.date);
                    thisBarData.push(item.totalScore)
                })
            }
            // 最大值
            let maxNumber = Math.max(...thisBarData);
            // 最小值
            let minNumber = Math.min(...thisBarData);


            // 指定图表的配置项和数据
            let myChart = echarts.init(evalEchartRef.value);
            myChart.resize();

            let option = {
                grid: {
                    left: 0,
                    right: '5%',
                    top: '10%',
                    bottom: 0,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow',        // 默认为直线，可选为：'line' | 'shadow'
                        shadowStyle: 'rgba(54, 99, 225, 0.05)'
                    },
                    showContent:false, //不显示悬浮内容
                    // textStyle: {
                    //     fontFamily: "SysFontM",
                    //     align: 'left'
                    // },
                    // formatter: (params) => {
                    //     let html = `<div>${params[0].name}</div>`
                    //     params.forEach(item => {
                    //         html += `<div style='display: flex;align-items: center;justify-content: space-between;'>
                    //                 <div style='float:left'>
                    //                     <span style='display:inline-block;width:10px;height:10px;background:${this.titleColor[item.seriesIndex % 3].start};border-radius:50%'></span>
                    //                     <span >${this.templateTitleInfo.length ? this.$utils.getValueByKey(this.templateTitleInfo, item.seriesName, 'value', 'key') : item.seriesName}</span>
                    //                 </div>
                    //                 <div style='float:right;margin-left:10px'>
                    //                     <span class="SysFontM" >${item.data}</span>
                    //                 </div>
                    //             </div>`
                    //     })
                    //     return html
                    // }
                },
                //  ------  X轴 ------
                xAxis: {
                    type: "category",
                    interval: 0,
                    show: true,  // 是否显示
                    nameGap: 30, // 坐标轴名称与轴线之间的距离
                    axisLine: {       // 坐标轴 轴线
                        show: false,  // 是否显示
                    },
                    axisTick: {    // 坐标轴 刻度
                        show: false,
                    },
                    axisLabel: {      // 坐标轴的标签
                        show: true,    // 是否显示
                        interval: 0,
                        textStyle: {
                            color: '#81818A',
                            fontSize: 26,
                            fontFamily: "akrobatRegular",
                        }
                    },
                    data: thisBarDataX
                },
                //   ------   y轴  ----------
                yAxis: {
                    show: true,  // 是否显示
                    nameGap: 30, // 坐标轴名称与轴线之间的距离
                    axisLine: {    // 坐标轴 轴线
                        show: false,  // 是否显示
                    },
                    axisTick: {      // 坐标轴的刻度
                        show: false,    // 是否显示
                    },
                    axisLabel: {      // 坐标轴的标签
                        show: true,    // 是否显示
                        textStyle: {
                            color: '#B7B7BF',
                            fontSize: 40,
                            fontFamily: "SysFontM",
                        }
                    },
                    splitLine: {    // gird 区域中的分割线
                        show: true,   // 是否显示
                        lineStyle: {
                            color: '#DCDFE8',
                            width: 2,
                            type: 'dashed'
                        }
                    },
                },
                //  -------   内容数据 -------
                series: [
                    {
                        type: 'bar',      // 类型
                        label: {
                            show: true,
                            position: 'top',
                            distance: 18,
                            align: 'center',
                            verticalAlign: 'middle',
                            rotate: 0,
                            formatter: (ind) => {
                                let title = ind.data;
                                return title
                            },
                            fontSize: 24,
                            color: '#3A3A3A',
                            fontFamily: 'akrobatBold',
                        },
                        itemStyle: {    // 图形的形状
                            normal: {
                                //柱形图圆角，初始化效果
                                barBorderRadius: [5],
                                borderRadio: [5],
                                color: (params) => {
                                    return new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                        offset: 0,
                                        color:'#5A81ED'
                                    },
                                        {
                                            offset: 1,
                                            color: '#3663E1'
                                        }])
                                }
                            }
                        },
                        barWidth: 24,  // 柱形的宽度
                        barGap: '10%',
                        data: thisBarData,
                        animationDelay:  (idx) => {
                            return idx * 10*2;
                        },
                        markLine: {
                            silent: false,
                            symbol: "none",
                            z: 2,
                            lineStyle: {
                                width: 2
                            },
                            data: [
                                {
                                    name: "最小值",
                                    type: 'min',
                                    lineStyle: {
                                        type: 'solid',
                                        color: "#1EAA46",
                                    },
                                    yAxis: minNumber,
                                    label: {
                                        fontSize: '24',
                                        color:'#1EAA46',
                                    },
                                },
                                {
                                    name: "最大值",
                                    type: 'max',
                                    lineStyle: {
                                        type: 'solid',
                                        color: "#FF6336",
                                    },
                                    yAxis: maxNumber,
                                    label: {
                                        fontSize: '24',
                                        color:'#FF6336',
                                    },
                                }
                            ],
                        },
                    }
                ]
            };

            let dataZoom = [
                {
                    type: 'inside', // 鼠标拖动
                    start: 0,
                    end: 10,
                    zoomLock: true, // 禁止鼠标缩放
                },
                {
                    show: false, // 隐藏拖拽条
                    type: 'slider',
                    top: '92%',
                    start: 0,
                    end: 10,
                    zoomLock: true,
                }
            ];

            if (thisBarDataX.length > 10) option.dataZoom = dataZoom;


            myChart.on('click', (params) => {
                //先取消高亮
                myChart.dispatchAction({
                    type: 'downplay',
                    // 使用 name 来定位节点。
                    seriesIndex: '',
                    dataIndex: params.dataIndex
                });
                myChart.dispatchAction({
                    type: 'highlight',
                    seriesIndex: '',
                    // 使用 dataIndex 来定位节点。
                    dataIndex: params.dataIndex
                });
                // console.log(barSeriesData[params.dataIndex],'params');
                ctx.emit('changeEval',barSeriesData[params.dataIndex].id);

                myChart.setOption({});
            });

            myChart.setOption(option, true);
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        }
        watch(
            () => props.list,
            (newVal) => {
                // console.log('newVal',newVal)
                if (newVal) {
                    nextTick(() => {
                        initFn()
                    })
                }
            }, { immediate: true }
        );
        return {
            evalEchartRef,
        }
    }
})
</script>
<style lang="scss" scoped>
.echart {
    width: 100%;
    height: 100%;
}
</style>
