import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 时间格式化
import moment from 'moment';
// echarts 图表
import echarts from '@/lib/echarts'
import 'amfe-flexible/index.js'
import '@/assets/css/index.scss'; //global css
import server from '@/services/server.js'
import globalComponents from '@/components/global-components.js'
import utils from '@/utils/index.js'
import storage from '@/utils/storeage.js'
import staticJson from '@/assets/json/index'
import Config from './config'; // global baseUrl
import 'animate.css' // npm install animate.css --save安装，在引入
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn'
import 'element-plus/dist/index.css'
// 人脸识别模型
import {
    detectSingleFace,
    nets,
    mtcnn,
    ssdMobilenetv1,
    matchDimensions,
    resizeResults,
    draw,
    SsdMobilenetv1Options,
    MtcnnOptions,
    Box,
    detectAllFaces,
    loadSsdMobilenetv1Model,
    loadFaceRecognitionModel,
    loadFaceLandmarkModel
} from 'face-api.js';
nets['mtcnn'].loadFromUri('/models');
loadSsdMobilenetv1Model("/models"); // 模型
loadFaceRecognitionModel("/models"); // 模型
loadFaceLandmarkModel("/models"); // 模型

//videojs
import Video from 'video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'

const app = createApp(App)


app.use(router)
app.use(store)
app.use(ElementPlus, {
    locale: zhCn,
})

app.use(globalComponents)

app.config.globalProperties.$server = server	// 接口api
app.config.globalProperties.$sg = storage; // 本地存储
app.config.globalProperties.$utils = utils // 公共方法
app.config.globalProperties.$aiJson = staticJson // 公共ai资源
app.config.globalProperties.$moment = moment; // 时间格式化
app.config.globalProperties.$echarts = echarts; // echarts

// 人脸模型方法
app.config.globalProperties.MtcnnOptions = MtcnnOptions;
app.config.globalProperties.mtcnn = mtcnn;

// 不同俱乐部素材
app.config.globalProperties.$Config = Config;

// video.js 引入
app.config.globalProperties.$video = Video; // video


app.mount('#app')
