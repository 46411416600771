<template>
    <div class="logo"><img :src="$Config.UI_CONFIG.logo" alt=""></div>
</template>

<script>
export default {
    name: "index"
}
</script>

<style scoped lang="scss">
.logo {
    width: 330px;
    img {
        width: 100%;
    }
}
</style>