import ai from '@/components/ai/index.vue';
import noData from '@/components/no-data/index.vue';
import logo from '@/components/logo/index.vue';
import contentBg from '@/components/contentBg/index.vue';
import teamList from '@/components/teamList/index.vue';
import menuCard from '@/components/menuCard/index.vue';
import indexNum from '@/components/indexNum/index.vue';
import pagination from '@/components/pagination/index.vue';
import pageHead from '@/components/pageHead/index.vue';
import select from '@/components/select/index.vue';
import playerList from '@/components/playerList/index.vue';
import dataReportStructure from '@/components/dataReportStructure/index.vue';
import peopleHeader from '@/components/peopleHeader/index.vue';
import exerciseItem from '@/components/exerciseItem/index.vue';
import tags from '@/components/tags/index.vue';
import radarChart from '@/components/radarChart/index.vue';
import evalChart from '@/components/evalChart/index.vue';
import filterDate from '@/components/filter-date/index.vue';

const acmeEl = {
    install: function(Vue) {
        Vue.component('ai', ai); // 人物
        Vue.component('no-data', noData); // 无数据组件
        Vue.component('logo', logo); // logo
        Vue.component('content-bg', contentBg); // 黑板插槽组件
        Vue.component('team-list', teamList); // team卡片
        Vue.component('menu-card', menuCard); // 菜单卡片
        Vue.component('index-number', indexNum); // 标号
        Vue.component('pagination-com', pagination); // 分页
        Vue.component('page-head', pageHead); // 导航头
        Vue.component('select-style', select); // 下拉选择
        Vue.component('player-list', playerList); // 人员列表
        Vue.component('data-report-structure', dataReportStructure); // 数据报告的公共布局样式
        Vue.component('people-header', peopleHeader); // 人员介绍头部
        Vue.component('exercise-item', exerciseItem); // 教案
        Vue.component('tags', tags); // 教案
        Vue.component('radarChart', radarChart); // 雷达图
        Vue.component('evalChart', evalChart); // 雷达图
        Vue.component('filter-date', filterDate); // 时间筛选
        
    },
};
export default acmeEl;