import {createStore, useStore} from 'vuex';

import $router from '@/router/index.js'
import storage from '@/utils/storeage.js';
import $aiJson from '@/assets/json/index.js';
import $utils from '@/utils/index.js';
// import i18n from '../language/index';


const store = createStore({
    // strict: true, // 严格模式 不可直接赋值
    /* 切换账号除copy外不需要重置的state */
    state: {
        ws: null, // webSocket
        record: null, //多媒体对象，用来处理音频
        command: null,//最新语音汉字
        audioFormatRtcProto: null,
        globalAudio: null, // 播放的audio实例
        loginTime: null, // 登录倒计时
        fullScreen: false, // 是否全屏显示主内容 （智能人物是否显示）

        // language: storage.ls.get('language') || 'zh', // 语言环境
        loading: false,

        userData: {}, // 用户信息
        token: storage.ss.get('token') || '', // 登录凭证
        tenantData: storage.ss.get('tenantData') || '', // 梯队数据
        teamData: storage.ss.get('teamData') || '', // 梯队数据
        AnimationType: 'Speak', // 说话动作/手势动作 Speak/Head
        // AnimationType: 'Head', // 说话动作/手势动作 Speak/Head
        identifyData: {}, // 识别的额外数据，例序号，上一页，下一页

        playerPositionMap: storage.ss.get('playerPositionMap') || {}, // 球员位置map映射关系 如: forward: { label: '前锋', labelEn: 'Forward' }
        // coachPositionMap: storage.ss.get('coachPositionMap') || {}, // 教练员职位map映射关系 如: headCoach: { label: '主教练', labelEn: 'Head Coach' }
        playerPositionList: storage.ss.get('playerPositionList') || [], // 球员位置列表
        // coachPositionList: storage.ss.get('coachPositionList') || [], // 教练员职位列表

        planData: [],

        queryParams: {},
    },
    getters: {
        playerPositionMap(state) {
            return state.playerPositionMap || {};
        },
        // coachPositionMap(state) {
        //     return state.coachPositionMap || {};
        // },
        playerPositionList(state) {
            return state.playerPositionList || [];
        },
        // coachPositionList(state) {
        //     return state.coachPositionList || [];
        // },
    },

    mutations: {
        // 设置 loading
        setLoading(state, data) {
            state.loading = data;
        },
        // 设置 token
        setToken(state, token) {
            state.token = token;
            storage.ss.set('token', token);
        },
        // 设置 userData
        setUserData(state, userData) {
            state.userData = userData;
        },
        // 设置ai动画类型
        setAnimationType(state, AnimationType) {
            state.AnimationType = AnimationType;
        },
        // 设置识别的额外数据
        setIdentifyData(state, identifyData) {
            state.identifyData = identifyData;
        },

        // 设置全屏 人物是否显示
        setFullScreen(state, fullScreen) {
            state.fullScreen = fullScreen
        },
        // 设置语音相关
        setWs(state, ws) {
            state.ws = ws;
        },
        setRecord(state, record) {
            state.record = record;
        },
        setAudioFormatRtcProto(state, audioFormatRtcProto) {
            state.audioFormatRtcProto = audioFormatRtcProto;
        },

        // 设置全局播放audio
        setGlobalAudio(state, globalAudio) {
            state.globalAudio = globalAudio;
            // console.log(state.globalAudio.src)
        },
        // 设置全局语音
        setCommand(state, command) {
            state.command = command;
            // console.log($aiJson)
            // console.log($router)
            let mainAudio = $aiJson.mainAudio;//唤醒词

            mainAudio.some(mainAudioItem => {
                if (eval('/' + mainAudioItem + '/gi').test(command)) { // 触发了唤醒词
                    // console.log(this.commit())
                    // console.log('触发了唤醒词')
                    this.commit('setIdentifyData', {})

                    let currentRouteName = $router.currentRoute.value.name;

                    let flag = 0;

                    $aiJson.globalRouse.some(globalRouseItem => { // 全局指令

                        // console.log(globalRouseItem)
                        // console.log('全局指令')

                        if (globalRouseItem.disable && globalRouseItem.disable.length && globalRouseItem.disable.indexOf(currentRouteName) > -1) {
                            // instructFn(globalRouseItem, command)
                            // console.log('错错错')
                            return true
                        } else if (instructFn(globalRouseItem, command)) {
                            flag = 1;
                            return true
                        }
                    })

                    if (!flag) {
                        const routeData = $aiJson.routeList[currentRouteName] || $aiJson.dataReport[currentRouteName] || $aiJson.talentReport[currentRouteName] || $aiJson.otherList[currentRouteName];

                        if (routeData) {
                            if (routeData.identifyArr && routeData.identifyArr.length) {
                                // console.log(routeData.identifyArr);
                                flag = instructFn(routeData.identifyArr, command)
                                // console.log(flag)
                            }
                        }
                    }

                    if (!flag) {
                        $utils.playVoiceFn($aiJson['notIdentify'], 'Speak')
                    }

                    return true
                }
            })
        },
        // 设置/common/data数据
        setStateData(state, data) {
            let {key, value} = data;
            if (!state[key]) return;
            state[key] = value;
            storage.ss.set(key, value);
        },
        // 设置机构数据
        setTenantData(state, data) {
            state.tenantData = data;
            storage.ss.set('tenantData', data);
        },
        // 设置梯队数据
        setTeamData(state, data) {
            state.teamData = data;
            storage.ss.set('teamData', data);
        },
        // 设置训练计划数据
        setPlanData(state, data) {
            state.planData = data;
        },
        // 设置分页参数
        setQueryParams: (state, data) => {
            console.log(data)
            state.queryParams = data
        }
    },
    actions: {
        // 退出登录
        LogOut({commit, state}) {
            return new Promise(resolve => {
                commit('setLoading', false);
                commit('setToken', '');
                commit('setUserData', {});
                commit('setTenantData', {});
                commit('setAudioFormatRtcProto', null);
                state.loginTime ? clearTimeout(state.loginTime) : '';
                state.ws?.close();
                state.record?.stop();
                resolve('success');
            });
        }
    },
});

const instructFn = (instruct, text) => {
    let flag = 0;
    // console.log(instruct)
    // console.log(Array.isArray(instruct))

    if (instruct) {
        if (instruct.audio) {
            instruct.audio.some(itemAudio => {
                if (eval('/' + itemAudio + '/gi').test(text)) {
                    flag = 1;

                    $utils.playVoiceFn(instruct, 'Speak')

                    return true
                }
            })
        } else if (Array.isArray(instruct)) {
            let identifyArr = [];
            instruct.some(identifyArrItem => {

                identifyArr.push(...$aiJson[identifyArrItem])
            })

            identifyArr.some((itemAudio, index) => {
                if (eval('/' + itemAudio + '/gi').test(text)) {
                    flag = 1;

                    // console.log(itemAudio)
                    // console.log(index)
                    let instructItemScope = 0;
                    instruct.some(instructItem => {
                        if (index >= instructItemScope && index < (instructItemScope + $aiJson[instructItem].length)) {
                            // console.log(instructItem)
                            // 设置识别的额外数据
                            const identifyData = {
                                type: instructItem,
                                index: index, // 主要用于number类型
                            }
                            store.commit('setIdentifyData', identifyData)
                            flag = 1;
                            return true
                        }
                        instructItemScope += $aiJson[instructItem].length;
                        // console.log(instructItemScope)
                    })
                    // $utils.playVoiceFn(instruct, 'Speak')
                    // console.log(flag)
                    return true
                }
            })
        }

    }

    // console.log(flag)

    return flag
}


export default store
