<template>
    <div class="cardItem">
        <slot name="icon"></slot>
        <slot name="label"></slot>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {},
    setup(props) {
        // console.log(props.width)
        return {
            ...props
        }
    }
}
</script>

<style scoped lang="scss">
.cardItem {
    background: linear-gradient(180deg, rgba(15, 16, 22, 0.3) 0%, rgba(15, 16, 22, 0.21) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(27px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;
    cursor: pointer;
}
</style>