<template>
    <div class="teamList">
        <p v-for="item in teamList" class="teamItem" @click="$emit('callback', item)">{{ item.name }}</p>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        teamList: {
            type: Array,
            default: []
        }
    }
}
</script>

<style scoped lang="scss">
.teamList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;

    .teamItem {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 39px;
        width: 302px;
        color: rgba(255, 255, 255, .8);
        text-align: center;
        padding: 24px 10px;
        font-size: 28px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.072) 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(27px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 3;
        flex-grow: 0;
        margin-bottom: 32px;
        margin-right: 17px;
        box-sizing: border-box;

        &:nth-child(4n + 1) {
            margin-left: 45px;
        }
    }
}

</style>