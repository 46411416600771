<template>
    <div class="filter-date">
        <div @click="handleChangeDate('subtract')" class="btn" style="transform: rotate(180deg)">
            <img class="img" src="@i/schedule/next.svg" mode="widthFix" />
        </div>
        <div class="time_box akrobatRegular">{{ date.join('-') }}</div>
        <div @click="handleChangeDate('add')" class="btn">
            <img class="img" src="@i/schedule/next.svg" mode="widthFix" />
        </div>
    </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue';
export default defineComponent({
    name: 'filter-date',
    props: {
        date: {
            type: Array,
            default: () => [],
        },
        monthsLength: {
            type: Number,
            default: 6,
        }
    },
    setup(props) {
        const { proxy } = getCurrentInstance();
        // 时间变化时
        const handleChangeDate = (key) => {
            let date = props.date;
            for (let i = 0; i < date.length; i++) {
                date[i] = proxy.$moment(date[i])[key](props.monthsLength, 'months').format('YYYY/MM/DD');
            }
            proxy.$emit('change', date);
        };
        return {
            handleChangeDate,
        };
    },
});
</script>

<style lang="scss" scoped>
.filter-date {
    display: flex;
    align-items: center;
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        background: rgba(23, 24, 98, 0.4);
        border-radius: 12px;
        cursor: pointer;
        &:hover {
            background: #3663e1;
        }
        .img {
            width: 20px;
        }
    }
    .time_box {
        padding: 0 20px;
        height: 70px;
        line-height: 70px;
        margin: 0 12px;
        text-align: center;
        background: rgba(23, 24, 98, 0.4);
        backdrop-filter: blur(12px);
        border-radius: 12px;
        font-size: 32px;
        color: #ffffff;
    }
}
</style>
