import store from '@/store/index'
import $router from '@/router/index.js'
import baseCof from '@/config'; // 这里设置了一个文件作为所有请求的基础ip地址
import $aiJson from '@/assets/json/index.js';
import {createStore} from "vuex";




/**
 * 播放话术
 * @param params
 * @param aiType
 */
const playVoiceFn = (params, aiType, callback) => {

    if (!baseCof.UI_CONFIG.aiFlag) return;



    aiType = aiType ? aiType : 'Speak';

    store.commit('setAudioFormatRtcProto', null)
    store.state.ws?.close();
    store.state.record?.stop();

    const voiceArr = params.playVoice ? $aiJson[params.playVoice] : params;
    const voiceText = voiceArr[Math.floor(Math.random() * voiceArr.length)]

    store.state.globalAudio.src = baseCof.TTS_URL + voiceText;
    store.commit('setAnimationType', aiType);

    // console.log(store.state.AnimationType);
    store.state.globalAudio.play();
    store.state.globalAudio.onended = () => {
        console.log('播放结束!!!')
        loginTimeFn();
        if (!callback) {
            if (params.router && params.router === -1) {
                $router.go(-1)
            }else if (params.router) {
                $router.push(params.router)
            }
        }else{
            callback();
        }
        (!params.disableASR) ? store.state.record?.start() : '';
    }
}

const identifyFn = ({type, data, changePage, router}) => {
    console.log(data)
    let playVoice = ''
    if (type === 'prevPage') {
        if (data.page === 1) {
            console.log('第一页')
            playVoice = ['已经是第一页了'];
            playVoiceFn(playVoice, 'Speak')
            return;
        }
        playVoiceFn({playVoice: 'identify' }, 'Speak')
        data.page--
        changePage(data.page)
    } else if (type === 'nextPage') {
        if (data.page === data.totalPage) {
            console.log('最后一页')
            playVoice = ['已经是最后一页了'];
            playVoiceFn(playVoice, 'Speak')
            return;
        }
        playVoiceFn({playVoice: 'identify' }, 'Speak')
        data.page++
        changePage(data.page)
    } else if (type === 'number') {
        let item = {};
        const index = data.index - 6 // 6 是上一页+下一页json length
        if (index < data.showList.length) {
            item = data.showList[index]
            playVoiceFn({playVoice: 'identify' }, 'Speak', router(item))
        }else {
            playVoiceFn({playVoice: 'notIdentify' }, 'Speak')
        }
    }
}

// 登录倒计时
const loginTimeFn = () => {
    // console.log('开始计时五分钟');
    let time = 1000 * 60 * 5;
    store.state.loginTime ? clearTimeout(store.state.loginTime) : '';
    store.state.loginTime = setTimeout(() => {
        playVoiceFn($aiJson.close, 'Speak', function () {
            store.dispatch('LogOut').then(res => {
                if (res === 'success') {
                    $router.push({
                        path: '/',
                        name: 'home'
                    })
                }
            })
        })
    }, time)
}
/**
 * 分页
 * @param list 数据
 * @param page 当前页数
 * @param pageSize 每页条数
 */
const paginationFn = (list, page, pageSize) => {
    if (!list && !list.length) return;
    list = JSON.parse(JSON.stringify(list))
    let totalPage = Math.ceil(list.length / pageSize);
    let showList = list.splice(pageSize * (page - 1), pageSize)

    return {
        totalPage,
        page,
        showList
    }

}

//HEX十六进制颜色值转换为RGB(A)颜色值
const hexToRgb = (hex, opc) => {
    let rgbaArry = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt(
        '0x' + hex.slice(3, 5)
    )},${parseInt('0x' + hex.slice(5, 7))},${opc})`;
    return rgbaArry;
}

/*
 检测文件返回的格式
*/
const formatSuffix = (name) => {
    let arr = name.split('.');
    let ext = arr[arr.length - 1].toLowerCase();
    let fileTypeArr = ['mp4', 'ppt', 'xls', 'xlsx', 'doc', 'pdf', 'jpg', 'png', 'jpeg', 'mov'];
    if (fileTypeArr.includes(ext)) return ext;
    return 'other';
}

/*
 默认头像
*/
const defaultAvatar = (url) => {
    let manUrl = require('@i/common/defaultAvatarMan.svg')
    // let womanUrl = require('@i/common/defaultAvatarWoman.svg')
    let imgUrl = url || manUrl
    return imgUrl;
}

/*
 静态数据转换
 storeKey: vuex的key值
 valueKey: 页面需要展示的key值
*/
const getKeyValue = (storeKey, valueKey) => {
    const list = store.state[storeKey]
    if (!list) return '';
    let obj = list.find((item) => item.value == valueKey);
    if (!obj) return '';
    return obj.label;
}
// 判断数组返回翻译 list 数组 key后台数据 label需要值字段 value对比字段
// 例：list = [{name: '中文', nameEn: 'English', key: 'key1'}], 取'key1'的中英文
//    $utils.getValueByKey(list, 'key1', 'name', 'key');
const getValueByKey = (list, key, label, value) => {
    for (var i = 0; i < list.length; i++) {
        var item = list[i];
        if (item[value] == key) {
            return item[label];
        }
    }
}
/**
 * @desc 时分秒转成成秒
 * @param func 函数
 * @param e 10:10:10 格式的时间
 */
const changeTimeByH = (e) => {
    var time = e;
    var len = time.split(':');
    if (len.length == 3) {
        let hour = time.split(':')[0];
        let min = time.split(':')[1];
        let sec = time.split(':')[2];
        return Number(hour * 3600) + Number(min * 60) + Number(sec);
    }
    if (len.length == 2) {
        let min = time.split(':')[0];
        let sec = time.split(':')[1];
        return Number(min * 60) + Number(sec);
    }
    if (len.length == 1) {
        let sec = time.split(':')[0];
        return Number(sec);
    }
    return 0;
}

export default {
    playVoiceFn,
    identifyFn,
    paginationFn,
    hexToRgb,
    formatSuffix,
    defaultAvatar,
    getKeyValue,
    getValueByKey,
    changeTimeByH
}