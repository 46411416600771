// source: RealTimeChatService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.RealTimeChat.AudioChunkContent', null, global);
goog.exportSymbol('proto.RealTimeChat.AudioFormatContent', null, global);
goog.exportSymbol('proto.RealTimeChat.AudioResponseContent', null, global);
goog.exportSymbol('proto.RealTimeChat.AudioSpeechRecognitionResultContent', null, global);
goog.exportSymbol('proto.RealTimeChat.IntentType', null, global);
goog.exportSymbol('proto.RealTimeChat.RealTimeChatMessage', null, global);
goog.exportSymbol('proto.RealTimeChat.RealTimeChatMessageOperationType', null, global);
goog.exportSymbol('proto.RealTimeChat.RealTimeChatMessageType', null, global);
goog.exportSymbol('proto.RealTimeChat.Text2SpeechRequestContent', null, global);
goog.exportSymbol('proto.RealTimeChat.Text2SpeechResultContent', null, global);
goog.exportSymbol('proto.RealTimeChat.TranslationRequestContent', null, global);
goog.exportSymbol('proto.RealTimeChat.TranslationResultContent', null, global);
goog.exportSymbol('proto.RealTimeChat.Voice2TextRequestContent', null, global);
goog.exportSymbol('proto.RealTimeChat.Voice2TextResultContent', null, global);
goog.exportSymbol('proto.RealTimeChat.VoiceChatRequestContent', null, global);
goog.exportSymbol('proto.RealTimeChat.VoiceChatResultContent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.Text2SpeechRequestContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.Text2SpeechRequestContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.Text2SpeechRequestContent.displayName = 'proto.RealTimeChat.Text2SpeechRequestContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.Text2SpeechResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.Text2SpeechResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.Text2SpeechResultContent.displayName = 'proto.RealTimeChat.Text2SpeechResultContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.TranslationRequestContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.TranslationRequestContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.TranslationRequestContent.displayName = 'proto.RealTimeChat.TranslationRequestContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.TranslationResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.TranslationResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.TranslationResultContent.displayName = 'proto.RealTimeChat.TranslationResultContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.Voice2TextRequestContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.Voice2TextRequestContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.Voice2TextRequestContent.displayName = 'proto.RealTimeChat.Voice2TextRequestContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.Voice2TextResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.Voice2TextResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.Voice2TextResultContent.displayName = 'proto.RealTimeChat.Voice2TextResultContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.AudioFormatContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.AudioFormatContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.AudioFormatContent.displayName = 'proto.RealTimeChat.AudioFormatContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.AudioChunkContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.AudioChunkContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.AudioChunkContent.displayName = 'proto.RealTimeChat.AudioChunkContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.AudioSpeechRecognitionResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.AudioSpeechRecognitionResultContent.displayName = 'proto.RealTimeChat.AudioSpeechRecognitionResultContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.AudioResponseContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.AudioResponseContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.AudioResponseContent.displayName = 'proto.RealTimeChat.AudioResponseContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.RealTimeChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.RealTimeChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.RealTimeChatMessage.displayName = 'proto.RealTimeChat.RealTimeChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.VoiceChatRequestContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.VoiceChatRequestContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.VoiceChatRequestContent.displayName = 'proto.RealTimeChat.VoiceChatRequestContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RealTimeChat.VoiceChatResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RealTimeChat.VoiceChatResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RealTimeChat.VoiceChatResultContent.displayName = 'proto.RealTimeChat.VoiceChatResultContent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.Text2SpeechRequestContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.Text2SpeechRequestContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Text2SpeechRequestContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fontname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.Text2SpeechRequestContent}
 */
proto.RealTimeChat.Text2SpeechRequestContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.Text2SpeechRequestContent;
  return proto.RealTimeChat.Text2SpeechRequestContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.Text2SpeechRequestContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.Text2SpeechRequestContent}
 */
proto.RealTimeChat.Text2SpeechRequestContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.Text2SpeechRequestContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.Text2SpeechRequestContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Text2SpeechRequestContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFontname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.Text2SpeechRequestContent} returns this
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FontName = 2;
 * @return {string}
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.getFontname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.Text2SpeechRequestContent} returns this
 */
proto.RealTimeChat.Text2SpeechRequestContent.prototype.setFontname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.Text2SpeechResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.Text2SpeechResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.Text2SpeechResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Text2SpeechResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    audiourl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.Text2SpeechResultContent}
 */
proto.RealTimeChat.Text2SpeechResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.Text2SpeechResultContent;
  return proto.RealTimeChat.Text2SpeechResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.Text2SpeechResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.Text2SpeechResultContent}
 */
proto.RealTimeChat.Text2SpeechResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.Text2SpeechResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.Text2SpeechResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.Text2SpeechResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Text2SpeechResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string AudioUrl = 1;
 * @return {string}
 */
proto.RealTimeChat.Text2SpeechResultContent.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.Text2SpeechResultContent} returns this
 */
proto.RealTimeChat.Text2SpeechResultContent.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.TranslationRequestContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.TranslationRequestContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.TranslationRequestContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.TranslationRequestContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    langfrom: jspb.Message.getFieldWithDefault(msg, 2, ""),
    langto: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.TranslationRequestContent}
 */
proto.RealTimeChat.TranslationRequestContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.TranslationRequestContent;
  return proto.RealTimeChat.TranslationRequestContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.TranslationRequestContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.TranslationRequestContent}
 */
proto.RealTimeChat.TranslationRequestContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLangfrom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLangto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.TranslationRequestContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.TranslationRequestContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.TranslationRequestContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.TranslationRequestContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLangfrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLangto();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.RealTimeChat.TranslationRequestContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.TranslationRequestContent} returns this
 */
proto.RealTimeChat.TranslationRequestContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LangFrom = 2;
 * @return {string}
 */
proto.RealTimeChat.TranslationRequestContent.prototype.getLangfrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.TranslationRequestContent} returns this
 */
proto.RealTimeChat.TranslationRequestContent.prototype.setLangfrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string LangTo = 3;
 * @return {string}
 */
proto.RealTimeChat.TranslationRequestContent.prototype.getLangto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.TranslationRequestContent} returns this
 */
proto.RealTimeChat.TranslationRequestContent.prototype.setLangto = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.TranslationResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.TranslationResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.TranslationResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.TranslationResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.TranslationResultContent}
 */
proto.RealTimeChat.TranslationResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.TranslationResultContent;
  return proto.RealTimeChat.TranslationResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.TranslationResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.TranslationResultContent}
 */
proto.RealTimeChat.TranslationResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.TranslationResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.TranslationResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.TranslationResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.TranslationResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.RealTimeChat.TranslationResultContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.TranslationResultContent} returns this
 */
proto.RealTimeChat.TranslationResultContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.Voice2TextRequestContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.Voice2TextRequestContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Voice2TextRequestContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    language: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.Voice2TextRequestContent}
 */
proto.RealTimeChat.Voice2TextRequestContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.Voice2TextRequestContent;
  return proto.RealTimeChat.Voice2TextRequestContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.Voice2TextRequestContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.Voice2TextRequestContent}
 */
proto.RealTimeChat.Voice2TextRequestContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.Voice2TextRequestContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.Voice2TextRequestContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Voice2TextRequestContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes Data = 1;
 * @return {string}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes Data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.RealTimeChat.Voice2TextRequestContent} returns this
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string Language = 2;
 * @return {string}
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.Voice2TextRequestContent} returns this
 */
proto.RealTimeChat.Voice2TextRequestContent.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.Voice2TextResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.Voice2TextResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.Voice2TextResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Voice2TextResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.Voice2TextResultContent}
 */
proto.RealTimeChat.Voice2TextResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.Voice2TextResultContent;
  return proto.RealTimeChat.Voice2TextResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.Voice2TextResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.Voice2TextResultContent}
 */
proto.RealTimeChat.Voice2TextResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.Voice2TextResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.Voice2TextResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.Voice2TextResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.Voice2TextResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.RealTimeChat.Voice2TextResultContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.Voice2TextResultContent} returns this
 */
proto.RealTimeChat.Voice2TextResultContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.AudioFormatContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.AudioFormatContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.AudioFormatContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioFormatContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    channels: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bitdepth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    samplerate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.AudioFormatContent}
 */
proto.RealTimeChat.AudioFormatContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.AudioFormatContent;
  return proto.RealTimeChat.AudioFormatContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.AudioFormatContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.AudioFormatContent}
 */
proto.RealTimeChat.AudioFormatContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChannels(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBitdepth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSamplerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.AudioFormatContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.AudioFormatContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.AudioFormatContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioFormatContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannels();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBitdepth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSamplerate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 Channels = 1;
 * @return {number}
 */
proto.RealTimeChat.AudioFormatContent.prototype.getChannels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RealTimeChat.AudioFormatContent} returns this
 */
proto.RealTimeChat.AudioFormatContent.prototype.setChannels = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 BitDepth = 2;
 * @return {number}
 */
proto.RealTimeChat.AudioFormatContent.prototype.getBitdepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RealTimeChat.AudioFormatContent} returns this
 */
proto.RealTimeChat.AudioFormatContent.prototype.setBitdepth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 SampleRate = 3;
 * @return {number}
 */
proto.RealTimeChat.AudioFormatContent.prototype.getSamplerate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.RealTimeChat.AudioFormatContent} returns this
 */
proto.RealTimeChat.AudioFormatContent.prototype.setSamplerate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.AudioChunkContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.AudioChunkContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.AudioChunkContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioChunkContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.AudioChunkContent}
 */
proto.RealTimeChat.AudioChunkContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.AudioChunkContent;
  return proto.RealTimeChat.AudioChunkContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.AudioChunkContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.AudioChunkContent}
 */
proto.RealTimeChat.AudioChunkContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.AudioChunkContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.AudioChunkContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.AudioChunkContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioChunkContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes Data = 1;
 * @return {string}
 */
proto.RealTimeChat.AudioChunkContent.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes Data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.RealTimeChat.AudioChunkContent.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes Data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.RealTimeChat.AudioChunkContent.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.RealTimeChat.AudioChunkContent} returns this
 */
proto.RealTimeChat.AudioChunkContent.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.AudioSpeechRecognitionResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isintermediate: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.AudioSpeechRecognitionResultContent}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.AudioSpeechRecognitionResultContent;
  return proto.RealTimeChat.AudioSpeechRecognitionResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.AudioSpeechRecognitionResultContent}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsintermediate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.AudioSpeechRecognitionResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsintermediate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} returns this
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool IsIntermediate = 2;
 * @return {boolean}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.getIsintermediate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} returns this
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.setIsintermediate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional double Confidence = 3;
 * @return {number}
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.RealTimeChat.AudioSpeechRecognitionResultContent} returns this
 */
proto.RealTimeChat.AudioSpeechRecognitionResultContent.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.AudioResponseContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.AudioResponseContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.AudioResponseContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioResponseContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    audiourl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.AudioResponseContent}
 */
proto.RealTimeChat.AudioResponseContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.AudioResponseContent;
  return proto.RealTimeChat.AudioResponseContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.AudioResponseContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.AudioResponseContent}
 */
proto.RealTimeChat.AudioResponseContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudiourl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.AudioResponseContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.AudioResponseContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.AudioResponseContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.AudioResponseContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudiourl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string AudioUrl = 1;
 * @return {string}
 */
proto.RealTimeChat.AudioResponseContent.prototype.getAudiourl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.AudioResponseContent} returns this
 */
proto.RealTimeChat.AudioResponseContent.prototype.setAudiourl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Text = 2;
 * @return {string}
 */
proto.RealTimeChat.AudioResponseContent.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RealTimeChat.AudioResponseContent} returns this
 */
proto.RealTimeChat.AudioResponseContent.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.RealTimeChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.RealTimeChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.RealTimeChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operationtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    content: (f = msg.getContent()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.RealTimeChatMessage}
 */
proto.RealTimeChat.RealTimeChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.RealTimeChatMessage;
  return proto.RealTimeChat.RealTimeChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.RealTimeChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.RealTimeChatMessage}
 */
proto.RealTimeChat.RealTimeChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.RealTimeChat.RealTimeChatMessageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.RealTimeChat.RealTimeChatMessageOperationType} */ (reader.readEnum());
      msg.setOperationtype(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.RealTimeChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.RealTimeChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.RealTimeChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOperationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional RealTimeChatMessageType Type = 1;
 * @return {!proto.RealTimeChat.RealTimeChatMessageType}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.getType = function() {
  return /** @type {!proto.RealTimeChat.RealTimeChatMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.RealTimeChat.RealTimeChatMessageType} value
 * @return {!proto.RealTimeChat.RealTimeChatMessage} returns this
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RealTimeChatMessageOperationType OperationType = 2;
 * @return {!proto.RealTimeChat.RealTimeChatMessageOperationType}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.getOperationtype = function() {
  return /** @type {!proto.RealTimeChat.RealTimeChatMessageOperationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RealTimeChat.RealTimeChatMessageOperationType} value
 * @return {!proto.RealTimeChat.RealTimeChatMessage} returns this
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.setOperationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Any Content = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.RealTimeChat.RealTimeChatMessage} returns this
*/
proto.RealTimeChat.RealTimeChatMessage.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RealTimeChat.RealTimeChatMessage} returns this
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RealTimeChat.RealTimeChatMessage.prototype.hasContent = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.VoiceChatRequestContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.VoiceChatRequestContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.VoiceChatRequestContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    audiochunk: (f = msg.getAudiochunk()) && proto.RealTimeChat.AudioChunkContent.toObject(includeInstance, f),
    audioformat: (f = msg.getAudioformat()) && proto.RealTimeChat.AudioFormatContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.VoiceChatRequestContent}
 */
proto.RealTimeChat.VoiceChatRequestContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.VoiceChatRequestContent;
  return proto.RealTimeChat.VoiceChatRequestContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.VoiceChatRequestContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.VoiceChatRequestContent}
 */
proto.RealTimeChat.VoiceChatRequestContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RealTimeChat.AudioChunkContent;
      reader.readMessage(value,proto.RealTimeChat.AudioChunkContent.deserializeBinaryFromReader);
      msg.setAudiochunk(value);
      break;
    case 2:
      var value = new proto.RealTimeChat.AudioFormatContent;
      reader.readMessage(value,proto.RealTimeChat.AudioFormatContent.deserializeBinaryFromReader);
      msg.setAudioformat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.VoiceChatRequestContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.VoiceChatRequestContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.VoiceChatRequestContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudiochunk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RealTimeChat.AudioChunkContent.serializeBinaryToWriter
    );
  }
  f = message.getAudioformat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RealTimeChat.AudioFormatContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional AudioChunkContent AudioChunk = 1;
 * @return {?proto.RealTimeChat.AudioChunkContent}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.getAudiochunk = function() {
  return /** @type{?proto.RealTimeChat.AudioChunkContent} */ (
    jspb.Message.getWrapperField(this, proto.RealTimeChat.AudioChunkContent, 1));
};


/**
 * @param {?proto.RealTimeChat.AudioChunkContent|undefined} value
 * @return {!proto.RealTimeChat.VoiceChatRequestContent} returns this
*/
proto.RealTimeChat.VoiceChatRequestContent.prototype.setAudiochunk = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RealTimeChat.VoiceChatRequestContent} returns this
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.clearAudiochunk = function() {
  return this.setAudiochunk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.hasAudiochunk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioFormatContent AudioFormat = 2;
 * @return {?proto.RealTimeChat.AudioFormatContent}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.getAudioformat = function() {
  return /** @type{?proto.RealTimeChat.AudioFormatContent} */ (
    jspb.Message.getWrapperField(this, proto.RealTimeChat.AudioFormatContent, 2));
};


/**
 * @param {?proto.RealTimeChat.AudioFormatContent|undefined} value
 * @return {!proto.RealTimeChat.VoiceChatRequestContent} returns this
*/
proto.RealTimeChat.VoiceChatRequestContent.prototype.setAudioformat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RealTimeChat.VoiceChatRequestContent} returns this
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.clearAudioformat = function() {
  return this.setAudioformat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RealTimeChat.VoiceChatRequestContent.prototype.hasAudioformat = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.RealTimeChat.VoiceChatResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RealTimeChat.VoiceChatResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.VoiceChatResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    intent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    speechrecognitionresult: (f = msg.getSpeechrecognitionresult()) && proto.RealTimeChat.AudioSpeechRecognitionResultContent.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && proto.RealTimeChat.AudioResponseContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RealTimeChat.VoiceChatResultContent}
 */
proto.RealTimeChat.VoiceChatResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RealTimeChat.VoiceChatResultContent;
  return proto.RealTimeChat.VoiceChatResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RealTimeChat.VoiceChatResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RealTimeChat.VoiceChatResultContent}
 */
proto.RealTimeChat.VoiceChatResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.RealTimeChat.IntentType} */ (reader.readEnum());
      msg.setIntent(value);
      break;
    case 2:
      var value = new proto.RealTimeChat.AudioSpeechRecognitionResultContent;
      reader.readMessage(value,proto.RealTimeChat.AudioSpeechRecognitionResultContent.deserializeBinaryFromReader);
      msg.setSpeechrecognitionresult(value);
      break;
    case 3:
      var value = new proto.RealTimeChat.AudioResponseContent;
      reader.readMessage(value,proto.RealTimeChat.AudioResponseContent.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RealTimeChat.VoiceChatResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RealTimeChat.VoiceChatResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RealTimeChat.VoiceChatResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSpeechrecognitionresult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RealTimeChat.AudioSpeechRecognitionResultContent.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.RealTimeChat.AudioResponseContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntentType Intent = 1;
 * @return {!proto.RealTimeChat.IntentType}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.getIntent = function() {
  return /** @type {!proto.RealTimeChat.IntentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.RealTimeChat.IntentType} value
 * @return {!proto.RealTimeChat.VoiceChatResultContent} returns this
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.setIntent = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AudioSpeechRecognitionResultContent SpeechRecognitionResult = 2;
 * @return {?proto.RealTimeChat.AudioSpeechRecognitionResultContent}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.getSpeechrecognitionresult = function() {
  return /** @type{?proto.RealTimeChat.AudioSpeechRecognitionResultContent} */ (
    jspb.Message.getWrapperField(this, proto.RealTimeChat.AudioSpeechRecognitionResultContent, 2));
};


/**
 * @param {?proto.RealTimeChat.AudioSpeechRecognitionResultContent|undefined} value
 * @return {!proto.RealTimeChat.VoiceChatResultContent} returns this
*/
proto.RealTimeChat.VoiceChatResultContent.prototype.setSpeechrecognitionresult = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RealTimeChat.VoiceChatResultContent} returns this
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.clearSpeechrecognitionresult = function() {
  return this.setSpeechrecognitionresult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.hasSpeechrecognitionresult = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AudioResponseContent Response = 3;
 * @return {?proto.RealTimeChat.AudioResponseContent}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.getResponse = function() {
  return /** @type{?proto.RealTimeChat.AudioResponseContent} */ (
    jspb.Message.getWrapperField(this, proto.RealTimeChat.AudioResponseContent, 3));
};


/**
 * @param {?proto.RealTimeChat.AudioResponseContent|undefined} value
 * @return {!proto.RealTimeChat.VoiceChatResultContent} returns this
*/
proto.RealTimeChat.VoiceChatResultContent.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RealTimeChat.VoiceChatResultContent} returns this
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RealTimeChat.VoiceChatResultContent.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.RealTimeChat.RealTimeChatMessageType = {
  REALTIMECHATMESSAGETYPEUNKNOWN: 0,
  AUDIOFORMAT: 1,
  AUDIOCHUNK: 2,
  AUDIOREQUEST: 3,
  TEXT2SPEECHREQUEST: 4,
  TRANSLATIONREQUEST: 5,
  VOICE2TEXTREQUEST: 6,
  AUDIOSPEECHRECOGNITIONRESULT: 100,
  AUDIORESPONSE: 101,
  INTERRUPSIGNAL: 102,
  TEXT2SPEECHRESULT: 103,
  TRANSLATIONRESULT: 104,
  VOICE2TEXTRESULT: 105
};

/**
 * @enum {number}
 */
proto.RealTimeChat.RealTimeChatMessageOperationType = {
  REALTIMECHATMESSAGEOPERATIONTYPEUNKNOWN: 0,
  INFORM: 1,
  ACK: 2
};

/**
 * @enum {number}
 */
proto.RealTimeChat.IntentType = {
  INTENTUNKNOWN: 0,
  INTENTINTERRUPSIGNAL: 1
};

goog.object.extend(exports, proto.RealTimeChat);
