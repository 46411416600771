<template>
    <div class="select" :class="skin">
        <div class="show" @click="optionFlag = !optionFlag">
            <span class="name">{{ value[label] }}</span>
            <img src="@i/common/select.svg" alt="">
        </div>
        <div class="selectList" v-show="optionFlag">
            <p class="option" v-for="item in list" :key="item[key]" @click="changeFn(item)">{{ item[label] }}</p>
        </div>
    </div>
</template>

<script>
import {defineComponent, getCurrentInstance, nextTick, reactive, toRefs, watch} from "vue";

export default defineComponent({
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        list: {
            type: [Array, Object],
            default: () => []
        },
        value: {
            type: Object,
            default: () => {}
        },
        label: {
            type: String,
            default: 'name'
        },
        key: {
            type: String,
            default: 'id'
        },
        skin: {
            type: String,
            default: 'black'
        }
    },
    setup(props) {
        const {proxy} = getCurrentInstance();


        const state = reactive({
            ...toRefs(props),

            optionFlag: false
        })

        // console.log(props);

        const changeFn = (item) => {
            state.optionFlag = !state.optionFlag;

            proxy.$emit('changeFn', item)
        }

        return {
            ...toRefs(state),

            changeFn
        }
    }
})
</script>

<style scoped lang="scss">
.select {
    z-index: 12;
    width: 370px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    /* Note: backdrop-filter has minimal browser support */
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    height: 56px;
    &.white {
        background: rgba(23, 24, 98, 0.4);
        border: 1px solid rgba(255, 255, 255, 0.24);
        backdrop-filter: blur(12px);
        .name {
            width: 100%;
            color: rgba(255, 255, 255, .8);
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .show {
        height: 56px;
        line-height: 56px;
        font-size: 24px;
        position: relative;

        .name {
            margin-left: 16px;
        }

        img {
            width: 23.5px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .selectList {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10000;
        border: 2px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        backdrop-filter: blur(27px);
        background: rgba(15, 16, 22, .3);
        width: 100%;
        /* Note: backdrop-filter has minimal browser support */
        .option {
            font-size: 24px;
            height: 68px;
            line-height: 68px;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            color: rgba(255, 255, 255, .6);
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            &:hover {
                background: #714CC0;
                color: #fff;
            }
        }
    }
}
</style>