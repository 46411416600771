<template>
    <div class="home" :style="{backgroundImage: `url(${$Config.UI_CONFIG.homeBg})`}">
        <!--        <div>-->
        <!--            <button @click="openFaceFn">开启人脸识别</button>-->
        <!--            <button>关闭人脸识别</button>-->
        <!--        </div>-->
        <!--        <div style="margin-top: 30px">-->
        <!--            <button @click="playMedia">开启语音交互</button>-->
        <!--            <button @click="closeMedia">关闭语音交互</button>-->
        <!--        </div>-->
        <logo></logo>
        <div class="workItem animate__animated" :class="animated" v-if="workItemList.length">
            <div class="itemTeamName">{{ workItem.teamName }}</div>
            <div class="itemContent">
                <div class="contentTop">
                    <span class="itemType" v-if="workItem.itemType"
                          :style="{background: `linear-gradient(90deg, ${itemColor[workItem.itemType].start} 0%, ${itemColor[workItem.itemType].end}  100%)`}">{{itemColor[workItem.itemType].label}}</span>
                    <span
                        class="itemTime">{{ workItem.date }} {{ workItem.start }} - {{ workItem.end }}</span>
                </div>
                <p class="contentBottom">{{ workItem.title }}</p>
            </div>
        </div>
        <p class="faceBtn" @click="openFaceFn"><img :src="$Config.UI_CONFIG.homeFace" alt=""></p>
    </div>
</template>

<script>


import {defineComponent, toRefs, reactive, getCurrentInstance, ref, onMounted, computed, watch} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'

export default defineComponent({
    name: 'Home',
    components: {},
    setup() {
        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            workItemList: [],
            workItem: {},
            workItemIndex: 0,
            animated: '',
            animatedTime: null,

            itemColor: {
                training: {
                    value: 'training',
                    label: '训练',
                    start: "#7FD800",
                    end: "#00D560",
                },
                match: {
                    value: 'match',
                    label: '比赛',
                    start: "#7F6BFF",
                    end: "#A24AFF",
                },
                meeting: {
                    value: 'meeting',
                    label: '会议',
                    start: "#378CFF",
                    end: "#374BFF",
                },
                travel: {
                    value: 'travel',
                    label: '出差',
                    start: "#34B5E8",
                    end: "#15D4E9",
                },
                study: {
                    value: 'study',
                    label: '学习',
                    start: "#FDC71C",
                    end: "#FFB26B",
                },
                recovery: {
                    value: 'recovery',
                    label: '恢复训练',
                    start: "#00D08A",
                    end: "#00E2D5",
                }
            },


        });

        onMounted(() => {
            getCommonData();
            store.dispatch('LogOut')
        })
        const playMedia = () => {
            store.state.record?.start();
        }

        const getWorkItem = () => {
            proxy.$server.getAllWorkItem().then(res => {
                console.log(res)
                state.workItemList = res.data;
                state.workItem = state.workItemList[state.workItemIndex];

                state.animatedTime ? clearInterval(state.animatedTime) : '';

                state.animatedTime = setInterval(() => {
                    if (state.workItemIndex === state.workItemList.length - 1) {
                        state.workItemIndex = 0
                    }else {
                        state.workItemIndex++
                    }

                    state.animated = 'animate__fadeOutDown'

                    let setTimeoutTime = null;
                    setTimeoutTime = setTimeout(() => {
                        state.workItem = state.workItemList[state.workItemIndex];
                        state.animated = 'animate__fadeInDown';
                        clearInterval(setTimeoutTime)
                    }, 1500)

                }, 5000)

            })
        }
        // 公共数据集合
        const getCommonData = () => {
            // 球员位置
            const params = {
                key: proxy.$Config.POSITION
            };
            proxy.$server.getCommonData(params).then((res) => {
                handleData(res, 'player');
            });
        };
        // 处理数据
        const handleData = (oldList, type) => {
            let list = oldList.data || [];
            let objMap = {};
            list.forEach((item) => {
                objMap[item.key] = {
                    label: item.value,
                    labelEn: item.valueEn,
                };
            });
            let value = list.map((item) => {
                return {
                    value: item.key,
                    label: item.value,
                    labelEn: item.valueEn,
                };
            });
            const positionList = {
                key: `${type}PositionList`,
                value,
            };
            const positionMap = {
                key: `${type}PositionMap`,
                value: objMap,
            };
            proxy.$store.commit('setStateData', positionMap)
            proxy.$store.commit('setStateData', positionList)
        };

        /**
         * 断开所有链接
         */
        const closeMedia = () => {
            console.log('关了')
            store.commit('setAudioFormatRtcProto', null)
            store.state.ws?.close();
            store.state.record?.stop();
        }

        const openFaceFn = () => {
            // router.push('/login')
            router.push('/organization/index')
        }

        return {
            ...toRefs(state),

            openFaceFn,
            playMedia,
            closeMedia
        }
    },
})
</script>

<style lang="scss" scoped>
.home {
    height: 100%;
    background-size: auto 100%;
    background-position: center;
    position: relative;

    .workItem {
        width: 1155px;
        height: 236px;
        background: rgba(13, 0, 39, 0.4);
        box-shadow: 0 32px 32px rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(40px);
        border-radius: 8px;
        border: 2px solid;
        border-image-source: linear-gradient(180deg, #A968FF 0%, rgba(169, 104, 255, 0) 100%);
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        position: absolute;
        left: calc((100% - 1155px) / 2);
        top: 480px;

        .itemTeamName {
            flex: 1;
            font-size: 36px;
            line-height: 50px;
            color: #F2F2F2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }

        .itemContent {
            width: 935px;
            height: 156px;

            .contentTop {
                display: flex;
                align-items: center;

                .itemType {
                    height: 40px;
                    font-size: 28px;
                    line-height: 39px;
                    padding-right: 6px;
                    padding-left: 6px;
                    background: linear-gradient(90deg, #378CFF 0%, #374BFF 100%);
                    border-radius: 8px;
                    color: #fff;
                }

                .itemTime {
                    font-family: akrobatBold;
                    color: rgba(255, 255, 255, .6);
                    font-size: 40px;
                    line-height: 50px;
                    padding-left: 24px;
                }
            }

            .contentBottom {
                margin-top: 21px;
                font-size: 32px;
                line-height: 45px;
                font-family: PingFangMedium;
                color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }
    }

    .faceBtn {
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 175px;
        left: calc((100% - 120px) / 2);

        img {
            width: 100%;
        }
    }
}
</style>
