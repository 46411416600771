<template>
    <div class="contentBox">
        <div class="contentBg">
            <slot name="content"></slot>
        </div>
        <div class="shadow"></div>
    </div>
</template>

<script>
export default {
    name: "index"
}
</script>

<style scoped lang="scss">
    .contentBox {
        position: relative;
        .contentBg {
            width: 1390px;
            height: 816px;
            background-image: url("~@i/common/contentBg.svg");
            background-size: 100%;
            overflow-y: auto;
            position: absolute;
            top: 33px;
            left: 63px;
            padding: 17px;
            box-sizing: border-box;
            z-index: 1;
        }
        .shadow {
            position: absolute;
            width: 1256px;
            height: 70px;
            left: 130px;
            top: 840px;

            background: linear-gradient(180deg, #140F22 32%, rgba(20, 15, 34, 0) 100%);
            opacity: 0.5;
            filter: blur(30px);
            backdrop-filter: blur(22px);
            /* Note: backdrop-filter has minimal browser support */

            border-radius: 112px;
            z-index: 0;
        }
    }
</style>