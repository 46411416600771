<template>
    <p class="no-data">{{ text }}</p>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        text: {
            type: String,
            default: '暂无数据',
        },
    },
	setup(props) {
		return {
			...props
		}
	}
})
</script>
<style scoped lang="scss">
    .no-data {
        font-size: 32px;
        height: 100%;
        color: rgba(255, 255, 255, 0.8);
        font-family: PingFangMedium;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>