<template>
    <div style="height: 100%">
        <div class="contentTop">
            <p class="left">
                <slot name="left"></slot>
            </p>
            <p class="center">
                <slot name="center">程轩燕</slot>
            </p>
            <p class="right">
                <slot name="right">2022 12/12 - 12/23</slot>
            </p>
        </div>
        <div class="contentBottom" id="contentBottom"  @click="clearTimeFn">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            time: null,
            height: 0
        });

        const scroll = () => {
            var hScrollTop = document.getElementById('contentBottom').scrollTop;
            var hScrollHeight = document.getElementById('contentBottom').scrollHeight;
            var offsetHeight = document.getElementById('contentBottom').offsetHeight;

            // console.log(hScrollTop + " " + hScrollHeight + " " + this.height + ' ' + offsetHeight);
            if ((offsetHeight + hScrollTop) >= hScrollHeight) {//滚动条已经到了容器底部
                state.height = -1;
            }else if (hScrollTop === 0) {
                state.height = 1;
            }

            var h = hScrollTop + state.height;
            document.getElementById('contentBottom').scrollTop = h;

            state.time = window.requestAnimationFrame(scroll)
        }
        const clearTimeFn =() => {
            console.log('执行取消事件')
            window.cancelAnimationFrame(state.time)
            return false;
        }

        onMounted(() => {
            scroll();
        });

        return {
            clearTimeFn
        }
    }
})
</script>

<style scoped lang="scss">
.contentTop {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 98px;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    .center {
        color: #FFFFFF;
        font-size: 34px;
        line-height: 48px;
        flex: 1;
        text-align: center;
        font-family: PingFangMedium;
    }
    .left,.right {
        width: 350px;
        color: rgba(255, 255, 255, .9);
        font-family: akrobatBold;
        font-size: 34px;
        line-height: 42px;
    }
    .left {
        margin-left: 30px;
    }
    .right {
        text-align: right;
        padding-right: 38px;
        box-sizing: border-box;
    }
}
.contentBottom {
    height: calc(100% - 98px);
    overflow-y: auto;
}
</style>