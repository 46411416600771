import store from "../../store";

/**
 * 玫瑰静态json文件 语音
 */
export default {
    mainAudio: ["玫瑰","小枚","小玫","小梅","回归","违规"],
    identify: ["好的", "收到", "好的，收到。"],
    close: ["玫瑰不打扰您了，有需要再来找玫瑰，再见。", "玫瑰先退出了，不打扰您了。", "玫瑰先退出了，有什么需要您在来喊我。"],
    notIdentify: ["抱歉，您刚才说的我没有听清，能再说一遍吗？", "抱歉，刚才好像有点吵，能再说一遍吗？", "能在说一下吗？我刚才没听清。", "您刚才在跟我说嘛？抱歉能在说一下吗？"],
    loginSelect: ["请您选择需要登录的用户！"],
    loginSuccess: ["正在登录中请稍后！","马上为您登录！"],
    prevPage: ["上一页","前一页","前一夜"],
    nextPage: ["下一页","后一页","后一夜"],
    number: ["一","二","三","四","五","六","七","八","九","十","11","12"],

    /** 全局指令
     * key
     * audio 关键词
     * router 识别关键词后跳转页面 -1: (go -1)
     * enable 开启关键词的页面name，enable层级大于disable，存在enable将不判断disable
     * disable 禁用关键词的页面name
     * playVoice 识别关键词后播放的话术
     * disableASR: 禁用语音识别
     */
    globalRouse: [
        {
            key: 'close',
            audio: ["退出", "关闭", "退下"],
            router: {
                path: '/',
                name: 'home',
            },
            disable: ['home'],
            playVoice: 'close'
        },
        {
            key: 'back',
            audio: ["返回"],
            router: -1,
            disable: ['home'],
            playVoice: 'identify'
        },
        {
            key: 'backOrganization',
            audio: ["回到机构"],
            router: {
                path: '/organization/index',
                name: 'organization',
            },
            disable: ['home', 'login', 'team', 'organization'],
            playVoice: 'identify'
        },
        {
            key: 'backTeam',
            audio: ["回到梯队", "回到开始"],
            router: {
                path: '/team/index',
                name: 'team',
            },
            disable: ['home', 'login', 'team'],
            playVoice: 'identify'
        },
        {
            key: 'backMenu',
            audio: ["菜单", "回到开始"],
            router: {
                path: '/menu/index',
                name: 'mainMenu',
            },
            disable: ['home', 'login', 'team', 'mainMenu'],
            playVoice: 'identify'
        },
    ],

    /**
     * 为了播放跳转后后后后的播放对应语音及主菜单显示 -------*********
     * key 必须要和router内的name一直 必须必须必须
     * path 路由路径
     * name 路由名称
     * hidden 是否显示到主菜单内 true：不显示
     * isConcat 是否需要拼接播放的话术 flag: true需要拼接 concatType：拼接的类型是梯队还是球员。目前仅支持 tenant/team/player
     * playVoice 跳转后话术
     * playVoiceArr 跳转后话术与playVoice互斥
     * animationType ai人物动画类型 Speak/Head
     * identifyArr 识别其他语音 号码/上一页/下一页
     * children 子级
     * teamMenu 梯队菜单
     * baseMenu 梯队菜单
     * campMenu 训练营菜单
     */
    routeList: {
        organization: {
            text: "选择机构页面",
            router: {
                path: '/organization/index',
                name: 'organization',
            },
            hidden: true,
            isConcat: {
                flag: false,
                concatType: 'team/player'
            },
            playVoice: '',
            playVoiceArr: ["您好，我是人工智能玫瑰。","您好，我叫玫瑰。"],
            animationType: 'Speak',
            children: []
        },
        team: {
            text: "梯队页面",
            router: {
                path: '/team/index',
                name: 'team',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: "的菜单。",
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        introduce: {
            text: "机构介绍页面",
            router: {
                path: '/introduce/index',
                name: 'introduce',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的介绍。',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage'],
            children: []
        },
        mainMenu: {
            text: "菜单页面",
            router: {
                path: '/menu/index',
                name: 'mainMenu',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的主菜单',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        talent: {
            text: "精英人才库",
            router: {
                path: '/talent/index',
                name: 'talent',
            },
            hidden: true,
            isConcat: {
                flag: false,
                concatType: 'team'
            },
            playVoice: '这是精英人材库',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        school: {
            text: "校区",
            router: {
                path: '/school/index',
                name: 'school',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的校区',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage'],
            children: []
        },
        camp: {
            text: "训练营",
            router: {
                path: '/camp/index',
                name: 'camp',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的训练营列表',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        matchReport: {
            text: "比赛报告",
            router: {
                path: '/matchReport/index',
                name: 'matchReport',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的比赛报告',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        smartPitch: {
            text: "SmartPitch",
            router: {
                path: '/smartPitch/index',
                name: 'smartPitch',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的智慧球场',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            children: []
        },
        teamSummary: {
            text: "球队总结",
            router: {
                path: '/teamSummary/index',
                name: 'teamSummary',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球队总结，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage'],
            icon: require('@i/secondMenu/teamSummary.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: false
        },
        trainingPlan: {
            text: "训练计划",
            router: {
                path: '/trainingPlan/index',
                name: 'trainingPlan',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的训练计划，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/trainingPlan.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: true
        },
        injuryReport: {
            text: "伤病报告",
            router: {
                path: '/injuryReport/index',
                name: 'injuryReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的伤病报告，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/injuryReport.svg'),
            children: [],
            teamMenu: true,
            baseMenu: false,
            campMenu: true
        },
        videoList: {
            text: "视频",
            router: {
                path: '/videoList/index',
                name: 'videoList',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的视频，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/videoList.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: true
        },
        dataReport: {
            text: "数据报告",
            router: {
                path: '/dataReport/index',
                name: 'dataReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的数据报告模块，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/dataReport.svg'),
            children: [],
            teamMenu: true,
            baseMenu: false,
            campMenu: false
        },
        playerEval: {
            text: "球员评估",
            router: {
                path: '/playerList/index',
                name: 'playerList',
                query: {
                    pageType: 'playerEval',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team',
            },
            playVoice: '的球员评估，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/playerEval.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: true
        },
        staffEval: {
            text: "教练员评估",
            router: {
                path: '/staffList/index',
                name: 'staffList',
                query: {
                    pageType: 'staffEval',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的教练员评估，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/staffEval.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: false
        },
        playerList: {
            text: "球员信息",
            router: {
                path: '/playerList/index',
                name: 'playerList',
                query: {
                    pageType: 'playerList',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球员列表，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/playerList.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: true
        },
        staffList: {
            text: "教练员信息",
            router: {
                path: '/staffList/index',
                name: 'staffList',
                query: {
                    pageType: 'staffList',
                }
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的教练员列表，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/staffList.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: true
        },
        teamMatch: {
            text: "梯队赛程",
            router: {
                path: '/teamMatch/index',
                name: 'teamMatch',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的梯队赛程，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/teamMatch.svg'),
            children: [],
            teamMenu: true,
            baseMenu: true,
            campMenu: false
        },
        exerciseList: {
            text: "训练教案",
            router: {
                path: '/exerciseList/index',
                name: 'exerciseList',
            },
            hidden: true,
            isConcat: {
                flag: false,
                concatType: 'team'
            },
            playVoice: '这是训练教案，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/exerciseList.svg'),
            children: []
        },
        dreamTeam: {
            text: "最佳阵容",
            router: {
                path: '/dreamTeam/index',
                name: 'dreamTeam',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的最佳阵容，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/dreamTeam.svg'),
            children: [],
            teamMenu: false,
            baseMenu: false,
            campMenu: true
        },
        campSummary: {
            text: "总结报告",
            router: {
                path: '/camp/summary',
                name: 'campSummary',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的总结报告，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/campSummary.svg'),
            children: [],
            teamMenu: false,
            baseMenu: false,
            campMenu: true
        },
        elitePlayers: {
            text: "精英球员",
            router: {
                path: '/elitePlayers/index',
                name: 'elitePlayers',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的精英球员，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/elitePlayers.svg'),
            children: [],
            teamMenu: false,
            baseMenu: false,
            campMenu: true
        },
        topPhysical: {
            text: "TOP榜 - 体测",
            router: {
                path: '/topPhysical/index',
                name: 'topPhysical',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的top榜单，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/topPhysical.svg'),
            children: [],
            teamMenu: false,
            baseMenu: false,
            campMenu: true
        },
        topAnalysis: {
            text: "TOP榜单 - 分析",
            router: {
                path: '/topAnalysis/index',
                name: 'topAnalysis',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的top榜单，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/topAnalysis.svg'),
            children: [],
            teamMenu: false,
            baseMenu: false,
            campMenu: true
        },
    },
    // 数据报告
    dataReport: {
        matchDay: {
            text: "比赛日分析",
            router: {
                path: '/dataReport/matchDay/index',
                name: 'matchDay',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的比赛日分析，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/thirdMenu/matchDay.svg'),
            children: []
        },
        playerAnalysis: {
            text: "球员数据分析",
            router: {
                path: '/dataReport/playerAnalysis/details',
                name: 'playerAnalysis',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的球员数据分析，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/thirdMenu/playerAnalysis.svg'),
            children: []
        },
        teamAnalysis: {
            text: "梯队数据分析",
            router: {
                path: '/dataReport/teamAnalysis/index',
                name: 'teamAnalysis',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的梯队数据分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/teamAnalysis.svg'),
            children: []
        },
        intensityReport: {
            text: "强度报告",
            router: {
                path: '/dataReport/intensityReport/index',
                name: 'intensityReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的强度报告，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/thirdMenu/intensityReport.svg'),
            children: []
        },
        acReport: {
            text: "AC报告",
            router: {
                path: '/dataReport/acReport/index',
                name: 'acReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的AC报告，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/thirdMenu/acReport.svg'),
            children: []
        },
        aimReport: {
            text: "AC目标",
            router: {
                path: '/dataReport/aimReport/index',
                name: 'aimReport',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的AC目标报告，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/thirdMenu/aimReport.svg'),
            children: []
        },
    },

    // 精英人才库
    talentReport: {
        talentDetails: {
            text: "精英人才库详情",
            router: {
                path: '/talent/details',
                name: 'talentDetails',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的详情，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage', 'number'],
            icon: require('@i/secondMenu/playerList.svg'),
        }, // 精英人才库详情
        playerDetails: {
            text: "基础信息",
            router: {
                path: '/playerList/details',
                name: 'playerDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的基础信息，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
        }, // 球员详情
        injuryReportDetails: {
            text: "伤病医疗",
            router: {
                path: '/injuryReport/details',
                name: 'injuryReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的伤病医疗，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage'],
            icon: require('@i/secondMenu/injuryReport.svg'),
        },
        playerEvalDetail: {
            text: "评估报告",
            router: {
                path: '/playerList/evalDetail',
                name: 'playerEvalDetail',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的评估报告，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerEval.svg'),
        },
        matchReport: {
            text: "比赛报告",
            router: {
                path: '/matchReport/details',
                name: 'matchReport',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的比赛报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/matchDay.svg'),
        },
        videoClipsList: {
            text: "视频",
            router: {
                path: '/videoList/clipsList',
                name: 'videoClipsList',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的视频，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/videoList.svg'),
        },
        matchDayDetails: {
            text: "比赛日分析",
            router: {
                path: '/dataReport/matchDay/details',
                name: 'matchDayDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的比赛日分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/matchDay.svg'),
        },
        playerAnalysisDetails: {
            text: "球员数据分析",
            router: {
                path: '/dataReport/playerAnalysis/details',
                name: 'playerAnalysisDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的球员数据分析，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/playerAnalysis.svg'),
        },
        intensityReportDetails: {
            text: "强度报告",
            router: {
                path: '/dataReport/intensityReport/details',
                name: 'intensityReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的强度报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/intensityReport.svg'),
        },
        acReportDetails: {
            text: "AC报告",
            router: {
                path: '/dataReport/acReport/details',
                name: 'acReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的AC报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/acReport.svg'),
        },
        aimReportDetails: {
            text: "AC目标报告",
            router: {
                path: '/dataReport/aimReport/details',
                name: 'aimReportDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的AC目标报告，请查收！',
            animationType: 'Head',
            icon: require('@i/thirdMenu/aimReport.svg'),
        },
    },

    // 其他路由 比如登录/教练员评估/三级详情等
    otherList: {
        login: {
            key: 'login',
            router: {
                path: '/login',
                name: 'login',
            },
            identifyArr: ['prevPage', 'nextPage', 'number'],
        },
        staffEvalDetail: {
            text: "教练员评估",
            router: {
                path: '/staffList/evalDetail',
                name: 'staffEvalDetail',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的评估报告，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerEval.svg'),
        },
        staffDetails: {
            text: "教练员详情",
            router: {
                path: '/staffList/details',
                name: 'staffDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'player'
            },
            playVoice: '的详细信息，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerEval.svg'),
        },
        trainingPlanDetails: {
            text: "训练计划详情",
            router: {
                path: '/trainingPlan/details',
                name: 'trainingPlanDetails',
            },
            hidden: false,
            isConcat: {
                flag: true,
                concatType: 'team'
            },
            playVoice: '的训练详情，请查收！',
            animationType: 'Head',
            identifyArr: ['prevPage', 'nextPage'],
            icon: require('@i/secondMenu/playerList.svg'),
        },
        exerciseDetails: {
            text: "教案详情",
            router: {
                path: '/exerciseList/details',
                name: 'exerciseDetails',
            },
            hidden: false,
            isConcat: {
                flag: false,
                concatType: 'team'
            },
            playVoice: '这是教案详情，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
        },
        schoolDetails: {
            text: "教案详情",
            router: {
                path: '/school/details',
                name: 'schoolDetails',
            },
            hidden: false,
            isConcat: {
                flag: false,
                concatType: 'team'
            },
            playVoice: '这是校区详情，请查收！',
            animationType: 'Head',
            icon: require('@i/secondMenu/playerList.svg'),
        },
        moment: {
            text: "机构介绍-精彩瞬间",
            router: {
                path: '/introduce/moment',
                name: 'moment',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的精彩瞬间。',
            animationType: 'Head',
        },
        videoIntroduce: {
            text: "机构介绍-视频资料",
            router: {
                path: '/introduce/video',
                name: 'moment',
            },
            hidden: true,
            isConcat: {
                flag: true,
                concatType: 'tenant'
            },
            playVoice: '的视频资料。',
            animationType: 'Head',
        },
    }
}