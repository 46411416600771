import GAFC from "./gafc";
import RFBC from "./rfbc";
import WCFA from "./wcfa";
import TALENT from "./talent";

const json = {
    GAFC,
    RFBC,
    WCFA,
    TALENT
}

export default json[process.env.VUE_APP_AUDIENCE]