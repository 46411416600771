const storage = {
    /* localStorage */
    ls: {
        set(key, value, e = true) {
            if (e) {
                let data = window.btoa(encodeURIComponent(JSON.stringify(value)));
                localStorage.setItem(key, data);
                return;
            }
            localStorage.setItem(key, JSON.stringify(value));
        },
        get(key, e = true) {
            const obj = localStorage.getItem(key);
            if (obj) {
                try {
                    if (e) {
                        return JSON.parse(decodeURIComponent(window.atob(obj)));
                    }
                    return JSON.parse(obj);
                } catch {
                    return obj
                }
            } else {
                return null;
            }
        },
        remove(key) {
            localStorage.removeItem(key);
        },
        clear() {
            localStorage.clear();
        },
    },
    /* sessionStorage */
    ss: {
        set(key, value, e = true) {
            if (e) {
                let data = window.btoa(encodeURIComponent(JSON.stringify(value)));
                sessionStorage.setItem(key, data);
                return;
            }
            sessionStorage.setItem(key, JSON.stringify(value));
        },
        get(key, e = true) {
            const obj = sessionStorage.getItem(key);
            if (obj) {
                try {
                    if (e) {
                        return JSON.parse(decodeURIComponent(window.atob(obj)));
                    }
                    return JSON.parse(obj);
                } catch {
                    return obj
                }
            } else {
                return null;
            }
        },
        remove(key) {
            sessionStorage.removeItem(key);
        },
        clear() {
            sessionStorage.clear();
        },
    },
};
export default storage;