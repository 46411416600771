<template>
    <div class="global">
        <router-view @openRtc="playMedia" class="left" :style="store.state.fullScreen ? 'width: 100%' : ''"/>
        <div class="testBtn" style="position: fixed; top: 20px;right: 100px; display: none">
            <button @click="store.commit('setAnimationType', 'Speak');">说话动作</button>
            <button @click="store.commit('setAnimationType', 'Head2');">伸手动作</button>
            <button @click="store.commit('setAnimationType', 'Head');">伸手动作---新</button>
            <button @click="store.commit('setAnimationType', 'Heart');">鼓掌动作</button>
            <button @click="store.commit('setAnimationType', 'Go');">走动动作</button>
        </div>
        <div class="defaultAi"
             v-if="$Config.UI_CONFIG.aiFlag"
             :class="$Config.CLUB_NAME.toLowerCase() + 'DefaultAi'"
             :style="{backgroundImage: `url(${$Config.UI_CONFIG.defaultAi})`}"
             v-show="(store.state.AnimationType === 'defaultAi') && !store.state.fullScreen"></div>

        <div class="right"
             v-if="$Config.UI_CONFIG.aiFlag"
             :class="$Config.CLUB_NAME.toLowerCase() + 'Right'"
             v-show="(store.state.AnimationType !== 'defaultAi') && !store.state.fullScreen"
             ref="aiDom">
            <ai v-for="(item,key) in aiJson"
                :key="key"
                :data="item"
                :type="key"
                :ref="key"
                :marginLeft="marginLeft"
                v-show="store.state.AnimationType === key"></ai>

        </div>

        <audio ref="globalAudio" src=""></audio>
    </div>
</template>

<script>
// @ is an alias to /src
import baseCof from '@/config'; // 这里设置了一个文件作为所有请求的基础ip地址
//语音AI
import {Any} from 'google-protobuf/google/protobuf/any_pb';
import {
    AudioChunkContent,
    AudioFormatContent,
    AudioSpeechRecognitionResultContent,
    RealTimeChatMessage,
    RealTimeChatMessageOperationType,
    RealTimeChatMessageType,
} from '@/assets/protobuf/generated/RealTimeChatService_pb'
import {toArrayBuffer} from '@/assets/protobuf/pcm_utils.ts';


import {defineComponent, toRefs, reactive, getCurrentInstance, ref, onMounted, computed, watch} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'

export default defineComponent({
    name: 'App',
    components: {},
    setup() {
        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            targetPcmFormat: {
                channels: 1,
                sampleRate: 16000,
                bitDepth: 16,
            },


            marginLeft: 0,
            T: null, // 动画定时器
            aiJson: proxy.$Config.UI_CONFIG.ai
        });

        let globalAudio = ref('');
        const aiDom = ref(null);

        onMounted(() => {

            if (!proxy.$Config.UI_CONFIG.aiFlag) return;

            setTimeout(() => {
                store.commit('setGlobalAudio', globalAudio)
            })

            setAiDomWidth()
        })

        watch(
            () => store.state.AnimationType,
            (val) => {
                if (val && val !=='' && val !== 'defaultAi' && proxy.aiJson[val]) {
                    // console.log(val)
                    animationFn(proxy.aiJson[val])

                }
            }
        )

        const setAiDomWidth = () => {
            let obj = {}
            for (const aiJsonKey in state.aiJson) {
                let item = state.aiJson[aiJsonKey];
                const W = ((aiDom.value.offsetHeight / item.height) * item.width) * item.page;
                obj[aiJsonKey] = {
                    ...item,
                    dowWidth: W
                }
            }
            state.aiJson = obj;
            console.log(state.aiJson)
        }

        const animationFn = (item) => {
            // console.log(item)
            state.marginLeft = 0;
            const time = (item.time * 1000) / item.page;
            let num = 0;
            console.log(state.T)
            clearInterval(state.T)
            state.T = setInterval(() => {
                if (num === (item.page - 1)) {
                    store.commit('setAnimationType', 'defaultAi');
                    clearInterval(state.T)
                } else {
                    state.marginLeft = (num+1) * 100
                }
                // console.log(state.marginLeft)
                num++
            }, time)
        }



        /**
         * 开启语音方法
         * @param success
         * @param error
         */
        const getUserMedia = (
            success,
            error,
        ) => {
            const constraints = {
                audio: true,
            };
            if (navigator.mediaDevices.getUserMedia) {
                // 最新的标准API
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(success)
                    .catch(error);
            } else if (navigator.webkitGetUserMedia) {
                // webkit核心浏览器
                navigator.webkitGetUserMedia(constraints, success, error);
            } else if (navigator.mozGetUserMedia) {
                // firfox浏览器
                navigator.mozGetUserMedia(constraints, success, error);
            } else if (navigator.getUserMedia) {
                // 旧版API
                navigator.getUserMedia(constraints, success, error);
            }
        }

        /** 调用开启语音 **/
        const playMedia = () => {
            closeMedia();
            getUserMedia(
                streams => {
                    let Recorder = function (stream) {
                        const sampleBits = 16; //输出采样数位 8, 16
                        const sampleRate = 48000; //输出采样率
                        const context = new AudioContext();
                        const audioInput = context.createMediaStreamSource(stream);
                        const recorder = context.createScriptProcessor(4096, 1, 1);
                        const audioData = {
                            size: 0, //录音文件长度
                            buffer: [], //录音缓存
                            inputSampleRate: 16000, //输入采样率
                            inputSampleBits: 16, //输入采样数位 8, 16
                            outputSampleRate: sampleRate, //输出采样数位
                            oututSampleBits: sampleBits, //输出采样率
                            clear: function () {
                                this.buffer = [];
                                this.size = 0;
                            },
                            input: function (data) {
                                this.buffer.push(new Float32Array(data));
                                this.size += data.length;
                            },
                        };

                        const sendData = (message) => { //对以获取的数据进行处理(分包)

                            if (message != null) {
                                // console.log(message)
                                // 开启WebSocket
                                webSocketOnOpen(message);

                            }
                            audioData.clear();//每次发送完成则清理掉旧数据
                        };

                        this.start = () => {
                            audioInput.connect(recorder);
                            recorder.connect(context.destination);
                        }

                        this.stop = () => {
                            recorder.disconnect();
                        }

                        recorder.onaudioprocess = function (e) {
                            // var inputBuffer = e.inputBuffer.getChannelData(0);
                            const inputBuffer = e.inputBuffer;
                            // 用于打开webSocket 设置初始传输（audioFormatRtcProto）
                            if (!store.state.audioFormatRtcProto) {
                                const rawAudioFormat = {
                                    float: true,
                                    channels: inputBuffer.numberOfChannels,
                                    sampleRate: inputBuffer.sampleRate,
                                    interleaved: false,
                                    bitDepth: inputBuffer.bitDepth,
                                };
                                const audioFormatProto = new AudioFormatContent();
                                audioFormatProto.setChannels(rawAudioFormat.channels);
                                audioFormatProto.setBitdepth(rawAudioFormat.bitDepth);
                                audioFormatProto.setSamplerate(rawAudioFormat.sampleRate);
                                // console.log(audioFormatProto);

                                const messageProto = new RealTimeChatMessage();
                                messageProto.setType(RealTimeChatMessageType.AUDIOFORMAT);
                                messageProto.setOperationtype(RealTimeChatMessageOperationType.INFORM);
                                const content = new Any();
                                content.pack(audioFormatProto.serializeBinary(), 'RealTimeChat.AudioFormatContent');
                                messageProto.setContent(content);

                                // console.log(messageProto);
                                store.commit('setAudioFormatRtcProto', messageProto)
                            }
                            const resampled = toArrayBuffer(inputBuffer, state.targetPcmFormat);
                            const audioChunkProto = new AudioChunkContent();
                            audioChunkProto.setData(new Uint8Array(resampled));
                            const messageProto = new RealTimeChatMessage();
                            messageProto.setType(RealTimeChatMessageType.AUDIOCHUNK);
                            messageProto.setOperationtype(RealTimeChatMessageOperationType.INFORM);
                            const content = new Any();
                            content.pack(audioChunkProto.serializeBinary(), 'RealTimeChat.AudioChunkContent');
                            messageProto.setContent(content);
                            // console.log(messageProto)
                            audioData.input(inputBuffer);
                            sendData(messageProto);
                        }
                    }

                    store.commit('setRecord', new Recorder(streams))
                    console.log('开始对讲');
                    store.state.record.start()
                },
                error => (() => {
                    console.log('开启语音失败')
                }),
            )
        };

        /**
         * 打开webSocket
         * @param message
         */
        const webSocketOnOpen = (message) => {
            if (store.state.ws && (store.state.ws.readyState === WebSocket.OPEN || store.state.ws.readyState === WebSocket.CONNECTING)) {
                if (store.state.ws.readyState === WebSocket.OPEN) {
                    store.state.ws.send(message.serializeBinary().buffer);
                }
            } else {
                console.log(baseCof.RTC_URL)
                store.commit('setWs', new WebSocket(baseCof.RTC_URL))

                bindWebSocketEventListeners()
            }

        }

        /**
         * 监听webSocket事件
         */
        const bindWebSocketEventListeners = () => {
            if (store.state.ws) {
                store.state.ws.onmessage = webSocketOnMessage;
                store.state.ws.onopen = () => {
                    webSocketOnOpen(store.state.audioFormatRtcProto)
                }; // 监听打开事件
                store.state.ws.onerror = webSocketOnError;
                store.state.ws.onclose = webSocketOnClose;
            }
        }

        /**
         * webSocket返回的数据
         * @param evt
         */
        const webSocketOnMessage = (evt) => {
            const blob = evt.data;
            const fileReader = new FileReader();
            // console.log(blob)
            fileReader.readAsArrayBuffer(blob);
            fileReader.onload = () => {
                const message = RealTimeChatMessage.deserializeBinary(new Uint8Array(fileReader.result));
                // console.log(message.getType());
                if (message.getType() === RealTimeChatMessageType.AUDIOSPEECHRECOGNITIONRESULT) {
                    const content = message
                        .getContent()
                        .unpack(
                            AudioSpeechRecognitionResultContent.deserializeBinary,
                            'RealTimeChat.AudioSpeechRecognitionResultContent',
                        );
                    // console.log(content)
                    getWebSocketBackMessage(content);
                }
            };
        }

        /**
         * 转换成文字输出
         * @param content
         */
        const getWebSocketBackMessage = (content) => {
            const isIntermediate = content.getIsintermediate();
            const srResult = content.getText().trim();
            if (!isIntermediate) {
                console.log(`srResult: (${isIntermediate}) ${srResult}`);
                // store.command = srResult;
                store.commit('setCommand', srResult)
            }
        }

        /**
         * webSocket失败
         * @param error
         */
        const webSocketOnError = (error) => {
            console.log('error');
            console.log(error)
        }

        /**
         * webSocket关闭
         */
        const webSocketOnClose = () => {
            console.log('close');
        }

        /**
         * 断开所有链接
         */
        const closeMedia = () => {
            store.commit('setAudioFormatRtcProto', null)
            store.state.ws?.close();
            store.state.record?.stop();
        }

        return {
            ...toRefs(state),
            store,
            globalAudio,
            playMedia,
            aiDom,
            setAiDomWidth
        }
    },
})
</script>

<style lang="scss" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .global {
        overflow: hidden;
        width: 100%;
        height: 100%;
        //display: flex;
        background-image: url("~@i/common/footballBg.png");
        background-size: auto 100%;
        background-position: center;
        position: relative;

        .left {
            width: 100%;
            box-sizing: border-box;
        }

        .right {
            //width: 977.5390625px;
            width: 423.1713722915299px;
            bottom: 0;
            overflow: hidden;
            position: absolute;
            height: 770px;
            z-index: 10;

        }

        .defaultAi {
            width: 423.1713722915299px;
            position: absolute;
            bottom: 0;
            height: 770px;
            z-index: 10;
            background-position: center;
            background-size: auto 100%;
            background-repeat: no-repeat;
        }
    }
}


// 北控样式
.rfbcRight {
    transform: scale(1.5);
    right: -288px;
    // 330
}
.rfbcDefaultAi {
    transform: scale(1.5);
    right: 0;
}
// 女足样式
.wcfaRight {
    transform: scale(1.6);
    right: 100px;
    // 330
}
.wcfaDefaultAi {
    transform: scale(1.6);
    right: 30px;
}
// 女足样式
.talentRight {
    transform: scale(1.6);
    right: 100px;
    // 330
}
.talentDefaultAi {
    transform: scale(1.6);
    right: 30px;
}

</style>
