<template>
    <div class="navigation-mian">
        <div class="navigation-title" @click="backFn">
            <img :src="src" class="icon" />{{title}}
        </div>
        <slot name="right"></slot>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        // 自定义回调
        callback: {
            type: Function
        },
        src:{
            type : String,
            default: require('@/assets/images/common/nav-back.svg'),
        }
    },
    setup(props) {
        const router = useRouter()
        const backFn = () => {
            // 自定义回调
            if(props.callback) {
                props.callback()
                return
            }
            router.go(-1)
        };
        return {
            ...props,
            backFn
        }
    },
})
</script>

<style scoped lang="scss">
    .navigation-mian {
        display: flex;
        padding: 40px 40px 0;
        .navigation-title {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-weight: 600;
            font-size: 36px;
            .icon {
                margin-right: 24px;
                width: 24px;
            }
        }
    }
</style>
